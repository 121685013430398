import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { React, Button, Modal, Container, Row, Validator } from '../import';
import axios from 'axios';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { DatePicker } from '@trussworks/react-uswds';
import SubHeader from './Components/SubHeader';
import ErrorDetails from './Components/ErrorDetails';
import Utils from '../utils';
const { REACT_APP_HOST_URL, REACT_APP_API_CUSTOMIZE_SANCTION_DATASET, REACT_APP_API_POST_CUSTOMIZE_SANCTION_DATASET, REACT_APP_PROGRAM_TAG_DEFINITIONS_URL, REACT_APP_OTHER_SANCTIONS_LIST_URL } = process.env;
const { useState, useEffect } = React;
// declare an axios request cancelToken (globally)
let axiosRequest;
const CustomizeSanctionsDataset = () => {
    var _a, _b;
    const incompleteClass = "usa-step-indicator__segment";
    const completeClass = "usa-step-indicator__segment usa-step-indicator__segment--complete";
    const currentClass = "usa-step-indicator__segment usa-step-indicator__segment--current";
    const [isOpen, openModal] = useState(false);
    const allLists = 'All Lists';
    const conLists = 'Consolidated List';
    const sdnLists = 'SDN List';
    const [allSDNCB, setAllIndex] = useState(-1);
    const [onlySDNCB, setSDNIndex] = useState(-1);
    const [consolidatedSDNCB, setConIndex] = useState(-1);
    const topLevelLists = [allLists, conLists, sdnLists];
    const [showFullSearchDescription, setFullSearchDescription] = useState(false);
    const [state, setState] = useState({
        sanctionsList: [],
        sanctionsProgram: [],
        publishedDate: undefined,
        initialPublishedDate: undefined,
        showSanctionsList: true,
        showPrograms: false,
        showPublishedDate: false,
        error: new Error(''),
        dateError: '',
        selected: [],
        sanctionsGroup: [],
        step1Class: currentClass,
        step2Class: incompleteClass,
        step3Class: incompleteClass,
        checkedItems: new Map(),
        isOpen: false
    });
    useEffect(() => {
        Utils.setMeta('name', 'Office of Foreign Assets Control\'s Customized Sanction Dataset');
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        const response = axios({
            method: "post",
            url: `${REACT_APP_HOST_URL}${REACT_APP_API_CUSTOMIZE_SANCTION_DATASET}`,
        }).then((response) => {
            const splitResult = splitIntoColumns(response.data.sanctionsList, 3);
            setState(Object.assign(Object.assign({}, state), { sanctionsList: response.data.sanctionsList, sanctionsProgram: response.data.sanctionsProgram, sanctionsGroup: splitResult, initialPublishedDate: response.data.initialPublishedDate }));
        }).catch((response) => {
            setState(Object.assign(Object.assign({}, state), { error: response }));
        });
    }, []);
    const closeModal = () => {
        if (typeof axiosRequest !== 'undefined') {
            axiosRequest.cancel('File download was canceled');
        }
        openModal(false);
    };
    //Gets the ID's of the following checkboxes
    const updateSDNIndxes = () => {
        for (let i = 0; i < state.sanctionsGroup.length; i++) {
            for (let j = 0; j < state.sanctionsGroup[i].length; j++) {
                if (state.sanctionsGroup[i][j].description == allLists)
                    setAllIndex(state.sanctionsGroup[i][j].id.toString());
                if (state.sanctionsGroup[i][j].description == sdnLists)
                    setSDNIndex(state.sanctionsGroup[i][j].id.toString());
                if (state.sanctionsGroup[i][j].description == conLists)
                    setConIndex(state.sanctionsGroup[i][j].id.toString());
            }
        }
    };
    const handleChange = e => {
        const item = e.target.id;
        const isChecked = e.target.checked;
        const sanctionList = state.sanctionsGroup;
        const map = new Map(state.checkedItems);
        //added temp variables as the state doesn't update immediately
        //which causes the checkboxes to be clicked twice to react otherwise
        let allSDNTemp = allSDNCB;
        let onlySDNTemp = onlySDNCB;
        let consolidatedSDNTemp = consolidatedSDNCB;
        if (allSDNCB == -1 && onlySDNCB == -1 && consolidatedSDNCB == -1) {
            updateSDNIndxes();
            for (let i = 0; i < state.sanctionsGroup.length; i++) {
                for (let j = 0; j < state.sanctionsGroup[i].length; j++) {
                    if (state.sanctionsGroup[i][j].description == allLists) {
                        setAllIndex(state.sanctionsGroup[i][j].id.toString());
                        allSDNTemp = state.sanctionsGroup[i][j].id.toString();
                    }
                    if (state.sanctionsGroup[i][j].description == sdnLists) {
                        setSDNIndex(state.sanctionsGroup[i][j].id.toString());
                        onlySDNTemp = state.sanctionsGroup[i][j].id.toString();
                    }
                    if (state.sanctionsGroup[i][j].description == conLists) {
                        setConIndex(state.sanctionsGroup[i][j].id.toString());
                        consolidatedSDNTemp = state.sanctionsGroup[i][j].id.toString();
                    }
                }
            }
        }
        if (item == allSDNTemp) {
            for (let i = 0; i < state.sanctionsGroup.length; i++) {
                for (let j = 0; j < state.sanctionsGroup[i].length; j++) {
                    map.set(sanctionList[i][j].id.toString(), isChecked);
                }
            }
        }
        else if (item == consolidatedSDNTemp) {
            for (let i = 0; i < state.sanctionsGroup.length; i++) {
                for (let j = 0; j < state.sanctionsGroup[i].length; j++) {
                    if (sanctionList[i][j].id.toString() != allSDNTemp && sanctionList[i][j].id.toString() != onlySDNTemp)
                        map.set(sanctionList[i][j].id.toString(), isChecked);
                }
            }
        }
        else {
            map.set(item, isChecked);
        }
        setState(prevState => (Object.assign(Object.assign({}, state), { checkedItems: map })));
    };
    const hideComponent = (name, direction) => {
        switch (name) {
            case "sanctionsList":
                setState(Object.assign(Object.assign({}, state), { showSanctionsList: false, showPrograms: true, showPublishedDate: false, step1Class: completeClass, step2Class: currentClass, step3Class: incompleteClass }));
                break;
            case "programs":
                {
                    setState(Object.assign(Object.assign({}, state), { showPrograms: false, showSanctionsList: direction !== "up", showPublishedDate: direction === "up", step1Class: direction === "up" ? completeClass : currentClass, step2Class: direction === "up" ? completeClass : incompleteClass, step3Class: direction === "up" ? currentClass : incompleteClass }));
                }
                break;
            case "publishedDate":
                setState(Object.assign(Object.assign({}, state), { showPublishedDate: false, showSanctionsList: false, showPrograms: true, step1Class: completeClass, step2Class: currentClass, step3Class: incompleteClass, dateError: '' }));
                break;
        }
    };
    const getDateMesssage = (type, initialPublishedDate) => {
        if (type === "initial") {
            const firstPublishedDate = new Date(initialPublishedDate);
            return (_jsxs("div", { children: ["Invalid date selection. Please enter a Published Date on or after ", (firstPublishedDate.getMonth() + 1) + "/" + firstPublishedDate.getDate() + "/" + firstPublishedDate.getFullYear(), "."] }, void 0));
        }
        else if (type === "noData") {
            return (_jsx("div", { children: "No results found." }, void 0));
        }
        else {
            return (_jsx("div", { children: "You cannot select a published date in the future." }, void 0));
        }
    };
    const downloadFile = () => {
        if (state.publishedDate !== undefined && state.publishedDate.toString() !== '') {
            const theDate = Validator.toDate(state.publishedDate);
            if (!Validator.isDate(theDate)) {
                setState(Object.assign(Object.assign({}, state), { dateError: 'The published as of date is invalid' }));
                return;
            }
            if (state.initialPublishedDate !== undefined) {
                if (new Date(theDate.toDateString()) < new Date(Validator.toDate(state.initialPublishedDate).toDateString())) {
                    setState(Object.assign(Object.assign({}, state), { dateError: getDateMesssage("initial", state.initialPublishedDate) }));
                    return;
                }
                else if (new Date(theDate.toDateString()) > new Date(getCurrentDate())) {
                    setState(Object.assign(Object.assign({}, state), { dateError: getDateMesssage(null, null) }));
                    return;
                }
            }
        }
        // cancel  previous request if exists
        if (typeof axiosRequest !== 'undefined') {
            axiosRequest.cancel('Previous file download was canceled');
        }
        // creates a new token for upcoming request (overwrite the previous one)
        axiosRequest = axios.CancelToken.source();
        openModal(true);
        setState(Object.assign(Object.assign({}, state), { step3Class: completeClass }));
        //remove unnecssary flags
        //Commented out until determined if needed or not
        /*
        const tempCheckedItems = Array.from(state.checkedItems.keys());
        let index = -1;

        if (allSDN == -1 && onlySDN == -1 && consolidatedSDN == -1) {
            updateSDNIndxes();
        }

        index = tempCheckedItems.indexOf(allSDN);
        if(index != -1)
            tempCheckedItems.splice(index, 1);

        index = tempCheckedItems.indexOf(onlySDN);
        if (index != -1)
            tempCheckedItems.splice(index, 1);

        index = tempCheckedItems.indexOf(consolidatedSDN);
        if (index != -1)
            tempCheckedItems.splice(index, 1);

        const payload = {
            sanctionsList: tempCheckedItems,
            SanctionsProgram: state.selected,
            DatePublished: state.publishedDate
        };
        */
        const payload = {
            sanctionsList: Array.from(new Map([...state.checkedItems].filter(([k, value]) => value === true)).keys()),
            SanctionsProgram: state.selected,
            DatePublished: state.publishedDate
        };
        axios({
            url: `${REACT_APP_HOST_URL}${REACT_APP_API_POST_CUSTOMIZE_SANCTION_DATASET}`,
            method: 'POST',
            responseType: 'blob',
            headers: { 'Content-Disposition': 'attachment' },
            data: payload,
            cancelToken: axiosRequest.token
        }).then((response) => {
            if (response.data.size === 0) {
                setState(Object.assign(Object.assign({}, state), { dateError: getDateMesssage("noData", null) }));
            }
            else {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'CustomizeSanctionsDataset.xml');
                document.body.appendChild(link);
                link.click();
                link.remove();
                setState(Object.assign(Object.assign({}, state), { step3Class: completeClass, dateError: '' }));
            }
            openModal(false);
        }).catch((response) => {
            if (axios.isCancel(response)) {
            }
            else {
                setState(Object.assign(Object.assign({}, state), { error: response }));
                openModal(false);
            }
        });
    };
    const splitIntoColumns = (array, colSize) => {
        const result = [];
        let tempAllList, tempSDNList, tempConList;
        //gets indexes of the top layer checkboxes and pull them first for display purposes
        for (let i = 0; i < array.length; i++) {
            if (array[i].description == allLists)
                tempAllList = array.splice(i, 1);
            if (array[i].description == sdnLists)
                tempSDNList = array.splice(i, 1);
            if (array[i].description == conLists)
                tempConList = array.splice(i, 1);
        }
        const arrayTemp = [tempAllList, tempSDNList, tempConList];
        //converts from Array[Array[]] to Array[Javascript Object]
        //as that is what the sytem is expecting
        const arrayTemp2 = arrayTemp.map(function (row) {
            return row[0];
        });
        //slice the fisrt three rows for display purposes
        //and then every 2 rows until the list is complete
        result.push(arrayTemp2.splice(0, 3));
        //No need to check array length as splice takes Array.length 
        //as the end paramter if the value is larger than the array size
        while (array.length > 0) {
            result.push(array.splice(0, 2));
        }
        return result;
    };
    const goBack = () => {
        history.back();
    };
    //gets the top layer checkbox for checkboxes such as "All" and "Consolidated"
    const getCheckboxSection = () => {
        return (_jsx("div", { children: state.sanctionsGroup.map((group, idx) => (_jsx("div", Object.assign({ className: "col" }, { children: group.filter(s => topLevelLists.indexOf(s.description) > -1).map((columnItem, itemIdx) => (_jsxs("div", Object.assign({ className: itemIdx === 0 ? 'pl-3 row form-group form-check' : 'pl-4 row form-group form-check' }, { children: [_jsx("input", { type: "checkbox", className: "form-check-input", id: columnItem.id, name: columnItem.description, checked: state.checkedItems.get(columnItem.id.toString()) || false, onChange: handleChange }, void 0),
                        _jsx("label", Object.assign({ className: "form-check-label font-weight-bold", htmlFor: columnItem.id }, { children: columnItem.description }), void 0)] }), itemIdx))) }), idx))) }, void 0));
    };
    //gets all other lists
    const getCheckboxSectionLower = () => {
        return (_jsx("div", Object.assign({ className: "border-bottom pl-5" }, { children: state.sanctionsGroup.map((group, idx) => (_jsx("div", Object.assign({ className: "col form-row " }, { children: group.filter(s => topLevelLists.indexOf(s.description) == -1).map((columnItem, itemIdx) => (_jsxs("div", Object.assign({ className: "col-6 form-group form-check" }, { children: [_jsx("input", { type: "checkbox", className: "form-check-input", id: columnItem.id, name: columnItem.description, checked: state.checkedItems.get(columnItem.id.toString()) || false, onChange: handleChange }, void 0),
                        _jsx("label", Object.assign({ className: "form-check-label", htmlFor: columnItem.id }, { children: columnItem.description }), void 0)] }), itemIdx))) }), idx))) }), void 0));
    };
    const handlePubDateChange = e => {
        setState(Object.assign(Object.assign({}, state), { publishedDate: e }));
    };
    if (state.error.message != '') {
        return (_jsx(ErrorDetails, { errorMessage: state.error.message }, void 0));
    }
    const { showSanctionsList, showPrograms, showPublishedDate } = state;
    const getCurrentDate = () => {
        const date = new Date();
        const currentMonth = date.getMonth() + 1;
        const today = date.getFullYear() + "-" + currentMonth + "-" + date.getDate();
        return today;
    };
    const showFullSearchDescriptionHandler = () => {
        setFullSearchDescription(!showFullSearchDescription);
    };
    const descriptionText = `OFAC’s Sanctions List Service - Customize Sanctions Dataset feature is designed for public users who need specific sanctions data for research, ` +
        `analysis, or other purposes. This functionality allows a user to generate a data output of one or more of OFAC’s sanctions lists in an XML format.\n\n` +
        `To use this functionality in creating a dataset tailored to your needs, users will need to follow three steps. Details for each are provided below:\n\n` +
        `<ol>` +
        `1. Selecting one or more of OFAC’s sanctions lists using the Sanctions Lists section of the screen (Step 1 of 3). Descriptions of each list option are provided below:\n\n` +
        `<ul style="list-style-type:none;">` +
        `<li><strong><u>Specially Designated Nationals and Blocked Persons List (SDN)</U></strong><br>As part of its enforcement efforts, OFAC publishes a list of individuals and companies ` +
        `owned or controlled by, or acting for or on behalf of, targeted countries. It also lists individuals, groups, and entities, such as terrorists and narcotics traffickers designated ` +
        `under programs that are not country-specific. Collectively, such individuals and companies are called "Specially Designated Nationals" or "SDNs." Their assets are blocked and U.S. persons ` +
        `are generally prohibited from dealing with them. Selecting the SDN option will include these data in the file output.</li >` +
        `<li><strong><u>Consolidated Sanctions List (Non-SDN Lists)</U></strong><br>In order to make it easier to comply with OFAC's sanctions regulations, OFAC offers all of its non-SDN sanctions ` +
        `lists in a consolidated set of data files "the Consolidated Sanctions List". While the consolidated sanctions list data are not part of OFAC's list of Specially Designated Nationals and ` +
        `Blocked Persons list, the records in the consolidated data may also appear on the SDN List. Selecting the consolidated list option will add all OFAC’s non-SDN sanctions lists to your data ` +
        `file output. When selected, users will notice all the non-SDN lists below the consolidated list option are automatically selected.</li >` +
        `<li><strong><u>Non-SDN Sanctions Lists, by list name</U></strong><br>OFAC maintains a number of non-SDN lists for specific authorities which have different requirements than that of the SDN ` +
        `List. Though most are non-blocking, they do contain information such as Directives, Prohibitions and Divestment requirements. ` +
        `<a href=${REACT_APP_OTHER_SANCTIONS_LIST_URL} className="blue-link" rel="noopener noreferrer" target="_blank">Descriptions for each of these lists can be found on OFAC’s website</a>. ` +
        `Users who would like to generate a custom dataset of this data, should select one or more of these lists which will result it being part of the data file output.</li >` +
        `</ul>` +
        `<br><br>After choosing the list(s) a user wants as their output file, select “next” in the lower right corner.<br><br>` +
        `2. On the Sanctions Programs portion of the screen (Step 2 of 3), users are provided the option to refine their data selections through filtering the list(s) they chose, by ` +
        `sanctions programs. To include all sanctions programs for the previously selected list(s), a user should click on “All Programs” from the Available Programs list, followed by ` +
        `“Add” button. This will move the “All Programs” option from the Available Programs box on the left to the Selected Programs box on the right. If a user wishes to select one or ` +
        `more specific sanctions programs as filter criteria, they may do so by clicking on the individual program(s) in the Available Programs list followed by the add button. ` +
        `<a href=${REACT_APP_PROGRAM_TAG_DEFINITIONS_URL} className="blue-link" rel="noopener noreferrer" target="_blank">Descriptions of these programs are available on OFAC’s website</a>.<br><br>After ` +
        `choosing the specific program(s) a user would like to filter by, select “next” in the lower right corner.<br><br>` +
        `3. On the Published Date portion of the screen (Step 3 of 3), users are provided the option to refine their data selections through filtering the list(s) and sanctions ` +
        `program(s) they chose, by publication date. To use this filter, users shall navigate to the date box and enter (or select) the publication date for the sanctions list data they ` +
        `are interested in. The use of this filter is not a required step and can be left blank. If left blank, the output files will not be filtered by date.\n\n` +
        `<strong>Important:</strong> The Published Date functionality only allows the user to select publication dates from when the first delta file was loaded into SLS (09/22/2022) – Present.\n\n` +
        `To generate the custom data file output, users will select “Download” in the lower right corner. The tool will generate a file containing the selected sanctions lists and/or programs ` +
        `based on your chosen parameters. As stated previously, the Customize Dataset is provided in XML format, which is a flexible, structured data format used widely for web applications, data ` +
        `exchange, and configuration files.` +
        `</ol>`;
    return (_jsxs("div", Object.assign({ className: "offset-1 col-md-10 offset-1" }, { children: [_jsx("title", { children: "Customize Sanctions Dataset - Sanctions List Service" }, void 0),
            _jsx(SubHeader, { headerTitle: "Customize Sanctions Dataset", headerDetails: "" }, void 0),
            _jsx(Row, Object.assign({ style: { paddingLeft: '15px' } }, { children: _jsxs("p", { children: [_jsx("div", { style: { whiteSpace: 'pre-wrap' }, dangerouslySetInnerHTML: { __html: showFullSearchDescription ? descriptionText : `${descriptionText.slice(0, 584)}` } }, void 0),
                        _jsxs("a", Object.assign({ href: "#", onClick: showFullSearchDescriptionHandler }, { children: [" Read ", showFullSearchDescription ? "Less" : "More"] }), void 0)] }, void 0) }), void 0),
            _jsx("hr", {}, void 0),
            _jsx("div", Object.assign({ className: "row" }, { children: _jsx("div", Object.assign({ className: "offset-2 col-8" }, { children: _jsx("div", Object.assign({ className: "usa-step-indicator usa-step-indicator--counters", "aria-label": "progress" }, { children: _jsxs("ol", Object.assign({ className: "usa-step-indicator__segments" }, { children: [_jsx("li", Object.assign({ className: state.step1Class }, { children: _jsx("span", Object.assign({ className: "usa-step-indicator__segment-label" }, { children: "Select Sanctions Lists " }), void 0) }), void 0),
                                _jsx("li", Object.assign({ className: state.step2Class }, { children: _jsx("span", Object.assign({ className: "usa-step-indicator__segment-label" }, { children: "Select Sanctions Programs " }), void 0) }), void 0),
                                _jsx("li", Object.assign({ className: state.step3Class }, { children: _jsx("span", Object.assign({ className: "usa-step-indicator__segment-label" }, { children: "Select Published Date " }), void 0) }), void 0),
                                _jsx("li", {}, void 0)] }), void 0) }), void 0) }), void 0) }), void 0),
            showSanctionsList && (_jsx("div", Object.assign({ id: "sanctionsListsSelection" }, { children: _jsx("div", Object.assign({ className: "row ofac__voffset-2" }, { children: _jsxs("div", Object.assign({ className: "border border-primary", style: { width: '100%' } }, { children: [_jsx("div", Object.assign({ className: "row", style: { margin: '0' } }, { children: _jsx("div", Object.assign({ className: "col ofac__bg-blue ofac__fg-white ofac__subheading" }, { children: _jsxs("h3", { children: ["Sanctions Lists ", _jsx("span", Object.assign({ className: "float-right" }, { children: "Step 1 of 3" }), void 0)] }, void 0) }), void 0) }), void 0),
                            _jsx("div", Object.assign({ className: "row" }, { children: _jsx("div", Object.assign({ className: "col pt-3 pb-1" }, { children: _jsxs("div", Object.assign({ className: "pl-3 pr-3" }, { children: [getCheckboxSection(), getCheckboxSectionLower(), _jsx("div", Object.assign({ className: "form-row mt-3 mb-2" }, { children: _jsx("div", Object.assign({ className: "col text-right" }, { children: _jsx("button", Object.assign({ id: "btnNext", type: "button", onClick: () => hideComponent("sanctionsList", "up"), className: "btn btn-primary usa-button usa-button pl-5 pr-5" }, { children: "Next" }), void 0) }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0)),
            showPrograms && (_jsx("div", Object.assign({ id: "sanctionsProgramsSelection" }, { children: _jsx("div", Object.assign({ className: "row ofac__voffset-2" }, { children: _jsxs("div", Object.assign({ className: "border border-primary", style: { width: '100%' } }, { children: [_jsx("div", Object.assign({ className: "row", style: { margin: '0' } }, { children: _jsx("div", Object.assign({ className: "col ofac__bg-blue ofac__fg-white ofac__subheading" }, { children: _jsxs("h3", { children: ["Sanctions Programs ", _jsx("span", Object.assign({ className: "float-right" }, { children: "Step 2 of 3" }), void 0)] }, void 0) }), void 0) }), void 0),
                            _jsxs("div", Object.assign({ className: "row" }, { children: [_jsx("div", Object.assign({ className: "col pt-3 pb-1 pl-5" }, { children: _jsx("h4", { children: "Available Programs:" }, void 0) }), void 0),
                                    _jsx("div", Object.assign({ className: "col pt-3 pb-1 pl-5" }, { children: _jsx("h4", { children: "Selected Programs:" }, void 0) }), void 0)] }), void 0),
                            _jsx("div", Object.assign({ style: { paddingLeft: '2%', paddingRight: '2%' } }, { children: _jsx(DualListBox, { options: state.sanctionsProgram.map(program => ({ value: program.id, label: program.description })), selected: state.selected, onChange: (selected) => {
                                        setState(Object.assign(Object.assign({}, state), { selected }));
                                    } }, void 0) }), void 0),
                            _jsx("div", Object.assign({ className: "row" }, { children: _jsx("div", Object.assign({ className: "col pt-3 pb-1" }, { children: _jsxs("form", Object.assign({ className: "pl-3 pr-3" }, { children: [_jsx("hr", {}, void 0),
                                            _jsxs("div", Object.assign({ className: "form-row mt-3 mb-2" }, { children: [_jsx("div", Object.assign({ className: "col text-left" }, { children: _jsx("button", Object.assign({ id: "btnPrevious", type: "button", className: "btn btn-light pl-5 pr-5 usa-button usa-button", onClick: () => hideComponent("programs", "down"), style: { borderColor: 'black' } }, { children: "< Previous" }), void 0) }), void 0),
                                                    _jsx("div", Object.assign({ className: "col text-right" }, { children: _jsx("button", Object.assign({ id: "btnNext", type: "button", onClick: () => hideComponent("programs", "up"), className: "btn btn-primary pl-5 pr-5 usa-button usa-button" }, { children: "Next" }), void 0) }), void 0)] }), void 0)] }), void 0) }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0)),
            showPublishedDate && (_jsx("div", Object.assign({ id: "publishedDateSelection" }, { children: _jsx("div", Object.assign({ className: "row ofac__voffset-2" }, { children: _jsxs("div", Object.assign({ className: "border border-primary", style: { width: '100%' } }, { children: [_jsx("div", Object.assign({ className: "row", style: { margin: '0' } }, { children: _jsx("div", Object.assign({ className: "col ofac__bg-blue ofac__fg-white ofac__subheading" }, { children: _jsxs("h3", { children: ["Published Date ", _jsx("span", Object.assign({ className: "float-right" }, { children: "Step 3 of 3" }), void 0)] }, void 0) }), void 0) }), void 0),
                            _jsx("div", Object.assign({ className: "row" }, { children: _jsxs("div", Object.assign({ className: "col pt-3 pb-1 pl-5" }, { children: [_jsx("h4", { children: "Published as of:" }, void 0),
                                        _jsx("div", Object.assign({ className: "usa-hint", id: "pub-date-hint" }, { children: "mm/dd/yyyy" }), void 0),
                                        _jsx(DatePicker, { onChange: handlePubDateChange, defaultValue: (_a = state.publishedDate) === null || _a === void 0 ? void 0 : _a.toString(), id: "pub-date", name: "pub-date", type: "text", "aria-describedby": "pub-date-hint", maxDate: getCurrentDate(), minDate: (_b = state.initialPublishedDate) === null || _b === void 0 ? void 0 : _b.toString() }, void 0),
                                        _jsx("span", Object.assign({ style: {
                                                fontWeight: 'bold',
                                                color: 'red',
                                            } }, { children: state.dateError }), void 0)] }), void 0) }), void 0),
                            _jsx("div", Object.assign({ className: "row" }, { children: _jsx("div", Object.assign({ className: "col pt-3 pb-1" }, { children: _jsxs("form", Object.assign({ className: "pl-3 pr-3" }, { children: [_jsx("hr", {}, void 0),
                                            _jsxs("div", Object.assign({ className: "form-row mt-3 mb-2" }, { children: [_jsx("div", Object.assign({ className: "col text-left" }, { children: _jsx("button", Object.assign({ id: "btnPrevious", type: "button", className: "btn btn-light pl-5 pr-5 usa-button usa-button", onClick: () => hideComponent("publishedDate", "down"), style: { borderColor: 'black' } }, { children: "< Previous" }), void 0) }), void 0),
                                                    _jsx("div", Object.assign({ className: "col text-right" }, { children: _jsx("button", Object.assign({ id: "btnDownload", type: "button", className: "btn btn-primary pl-5 pr-5 usa-button usa-button", onClick: () => downloadFile() }, { children: "Download" }), void 0) }), void 0)] }), void 0)] }), void 0) }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0)),
            _jsx(Modal, Object.assign({ show: isOpen, onHide: closeModal, backdrop: "static", centered: true, keyboard: false, tabIndex: -1, "aria-labelledby": "confirmPopupLabel", "aria-describedby": "confirmPopupDescribed" }, { children: _jsx(Modal.Dialog, Object.assign({ className: "modal-dialog-centered rounded" }, { children: _jsxs("div", Object.assign({ className: "modal-content" }, { children: [_jsx(Modal.Header, Object.assign({ className: "bg-light remove-bottom-border ofac__fg-white ofac__subheading float-left", id: "datasetLoadingPopupHeader" }, { children: _jsxs("div", Object.assign({ className: "w-100 text-uppercase" }, { children: [_jsx("i", { className: "fas fa-sync fa-3x" }, void 0),
                                        _jsx(Modal.Title, Object.assign({ id: "datasetLoadingPopupLabel", className: "text-dark" }, { children: "Dataset Loading..." }), void 0)] }), void 0) }), void 0),
                            _jsx(Modal.Body, Object.assign({ className: "modal-body .remove-bottom-border bg-light" }, { children: _jsxs(Container, Object.assign({ fluid: true }, { children: [_jsx("div", Object.assign({ className: "d-flex justify-content-center" }, { children: _jsx("div", Object.assign({ className: "spinner-border", role: "status" }, { children: _jsx("span", Object.assign({ className: "sr-only" }, { children: "Loading..." }), void 0) }), void 0) }), void 0),
                                        _jsx("br", {}, void 0),
                                        _jsx("p", { children: "The dataset you requested is processing, please wait..." }, void 0)] }), void 0) }), void 0),
                            _jsx(Modal.Footer, Object.assign({ className: "d-flex justify-content-center remove-top-border bg-light" }, { children: _jsx("div", { children: _jsx(Button, Object.assign({ id: "btnCancelDownload", type: "button", className: "btn btn-primary usa-button usa-button", "data-dismiss": "modal", onClick: closeModal }, { children: "Cancel Download" }), void 0) }, void 0) }), void 0)] }), void 0) }), void 0) }), void 0)] }), void 0));
};
export default CustomizeSanctionsDataset;
