import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { React, Row } from '../import';
const { useState, useEffect } = React;
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import SubHeader from './Components/SubHeader';
import ErrorDetails from './Components/ErrorDetails';
import Utils from '../utils';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
const { REACT_APP_HOST_URL, REACT_APP_API_SDN_LIST, REACT_APP_API_DOWNLOAD_EXPORT, REACT_APP_API_GET_FILE_EXPORTS, REACT_APP_SANCTIONS_PROGRAMS_URL, } = process.env;
const SdnList = () => {
    var _a;
    const Sanctions_programs_url = REACT_APP_SANCTIONS_PROGRAMS_URL;
    const [state, setState] = useState({
        data: [{
                fileName: '',
                description: '',
                size: 0,
                sizeFormatted: '',
                downloadLink: '',
                lastUpdated: undefined,
                hashCodes: ''
            }],
        error: new Error(''),
    });
    useEffect(() => {
        Utils.setMeta('name', 'Office of Foreign Assets Control\'s Specially Designated Nationals List - Data Formats & Data Schemas');
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        axios({
            method: "post",
            url: `${REACT_APP_HOST_URL}${REACT_APP_API_SDN_LIST}`,
        })
            .then((response) => {
            setState(Object.assign(Object.assign({}, state), { data: response.data }));
        })
            .catch((response) => {
            setState(Object.assign(Object.assign({}, state), { error: response }));
        });
    }, []);
    const downloadExport = (filename) => {
        axios({
            method: "post",
            url: `${REACT_APP_HOST_URL}${REACT_APP_API_DOWNLOAD_EXPORT}`,
            params: {
                filename: filename
            },
        })
            .then((response) => {
            const link = document.createElement('a');
            link.href = response.data;
            link.setAttribute('download', filename);
            link.setAttribute('target', "_blank");
            document.body.appendChild(link);
            link.click();
        })
            .catch((response) => {
            setState(Object.assign(Object.assign({}, state), { error: response }));
        });
    };
    const goBack = () => {
        // Getting errors when using () directly in render view so adding function to make the call instead.
        history.back();
    };
    const getRows = () => {
        let rows = [];
        // Publication Exports will have a file size greater than 0.
        rows = state.data.filter((data) => data.size > 0).map((row, index) => {
            const { fileName, description, sizeFormatted, downloadLink, hashCodes } = row;
            const borderBottomStyle = !hashCodes ? {} : { borderBottom: 'none' };
            const parsedHashCodes = !hashCodes ? '' : JSON.parse(hashCodes);
            const link = `${REACT_APP_HOST_URL}${REACT_APP_API_GET_FILE_EXPORTS}${'/' + fileName}`;
            return (_jsxs(_Fragment, { children: [_jsxs("tr", { children: [_jsx("td", Object.assign({ className: "ofac_table-cell text-bold", style: borderBottomStyle }, { children: fileName }), void 0),
                            _jsx("td", Object.assign({ className: "ofac_table-cell text-normal", style: borderBottomStyle }, { children: description }), void 0),
                            _jsx("td", Object.assign({ className: "ofac_table-cell text-normal", style: borderBottomStyle }, { children: sizeFormatted }), void 0),
                            _jsx("td", Object.assign({ className: "ofac_table-cell text-center" }, { children: _jsx("a", Object.assign({ href: link, className: "usa-button usa-button", rel: "noopener noreferrer", target: "_blank" }, { children: "Download" }), void 0) }), void 0)] }, fileName),
                    hashCodes &&
                        _jsx("tr", { children: _jsx("td", Object.assign({ colSpan: 4, style: { textAlign: 'right', borderTop: 'none' }, className: "ofac_table-cell text-normal" }, { children: _jsx(Accordion, Object.assign({ allowZeroExpanded: true }, { children: _jsxs(AccordionItem, { children: [_jsx(AccordionItemHeading, { children: _jsx(AccordionItemButton, { children: "File Signatures" }, void 0) }, void 0),
                                            _jsx(AccordionItemPanel, { children: _jsx("div", Object.assign({ style: { textAlign: 'left' } }, { children: Object.keys(parsedHashCodes).map(k => _jsxs("p", Object.assign({ style: { wordBreak: 'break-all', whiteSpace: 'normal' } }, { children: [_jsx("strong", { children: k }, void 0), ": ", parsedHashCodes[k]] }), k)) }), void 0) }, void 0)] }, fileName) }), void 0) }), void 0) }, void 0)] }, void 0));
        });
        return rows;
    };
    const getXSDRows = () => {
        let rows = [];
        // XSD files will have a size of 0
        rows = state.data.filter((data) => data.size == 0).map((row, index) => {
            const { fileName, description, sizeFormatted, downloadLink } = row;
            const link = `${REACT_APP_HOST_URL}${REACT_APP_API_GET_FILE_EXPORTS}${'/' + fileName}`;
            return _jsxs("tr", { children: [_jsx("td", Object.assign({ className: "ofac_table-cell font-weight-bold" }, { children: fileName }), void 0),
                    _jsx("td", Object.assign({ className: "ofac_table-cell font-weight-normal" }, { children: description }), void 0),
                    _jsx("td", { className: "ofac_table-cell font-weight-normal" }, void 0),
                    _jsx("td", Object.assign({ className: "ofac_table-cell text-center" }, { children: _jsx("a", Object.assign({ href: link, className: "usa-button usa-button", rel: "noopener noreferrer", target: "_blank" }, { children: "Download" }), void 0) }), void 0)] }, index);
        });
        return rows;
    };
    if (state.error.message != '') {
        return (_jsx(ErrorDetails, { errorMessage: state.error.message }, void 0));
    }
    return (_jsxs("div", Object.assign({ className: "offset-1 col-md-10 offset-1" }, { children: [_jsx("title", { children: "Specially Designated Nationals List - Sanctions List Service" }, void 0),
            _jsx(SubHeader, { headerTitle: "Specially Designated Nationals List", headerDetails: "" }, void 0),
            _jsx(Row, Object.assign({ style: { paddingLeft: '15px' } }, { children: _jsxs("p", { children: ["As part of its enforcement efforts, OFAC publishes a list of individuals and companies owned or controlled by, or acting for or on behalf of, targeted countries. It also lists individuals, groups, and entities, such as terrorists and narcotics traffickers designated under programs that are not country-specific. Collectively, such individuals and companies are called \"Specially Designated Nationals\" or \"SDNs.\" Their assets are blocked, and U.S. persons are generally prohibited from dealing with them. Read more information on\u00A0", _jsx("a", Object.assign({ href: Sanctions_programs_url, className: "blue-link", rel: "noopener noreferrer", target: "_blank" }, { children: "Treasury's Sanctions Programs" }), void 0), "."] }, void 0) }), void 0),
            _jsx("hr", {}, void 0),
            _jsx("div", Object.assign({ className: "row mt-1" }, { children: _jsx("div", Object.assign({ className: "col ofac__subheading text-capitalize text-primary" }, { children: _jsx("h3", { children: "Data Center - SDN List" }, void 0) }), void 0) }), void 0),
            _jsx("hr", {}, void 0),
            _jsx("div", Object.assign({ className: "row" }, { children: _jsx("div", Object.assign({ className: "col" }, { children: _jsxs("div", Object.assign({ style: { display: 'flex', justifyContent: 'space-between' } }, { children: [_jsx("p", Object.assign({ className: "font-weight-bold" }, { children: "Choose from the various data file types below to download a complete version of the SDN List." }), void 0),
                            _jsx("p", { children: _jsxs("i", { children: ["Last Updated: ", ((_a = state.data[0]) === null || _a === void 0 ? void 0 : _a.lastUpdated) === undefined ? "" : new Date(state.data[0].lastUpdated).toLocaleDateString()] }, void 0) }, void 0)] }), void 0) }), void 0) }), void 0),
            _jsx("div", Object.assign({ className: "row" }, { children: _jsx("div", Object.assign({ style: { borderTop: 'solid', borderTopWidth: '1px', width: '100%' } }, { children: _jsxs(Table, Object.assign({ responsive: true, className: "border border-primary" }, { children: [_jsx("thead", Object.assign({ className: "ofac__bg-lightgray" }, { children: _jsxs("tr", { children: [_jsx("th", Object.assign({ scope: "col", style: { width: '13%' } }, { children: "File Name" }), void 0),
                                        _jsx("th", Object.assign({ scope: "col", style: { width: '70%' } }, { children: "Description" }), void 0),
                                        _jsx("th", Object.assign({ scope: "col", style: { width: '7%' } }, { children: "Size" }), void 0),
                                        _jsx("th", { scope: "col", "aria-label": "Downloads Column", className: "text-center", style: { width: '10%' } }, void 0)] }, void 0) }), void 0),
                            _jsx("tbody", { children: getRows() }, void 0)] }), void 0) }), void 0) }), void 0),
            _jsx("hr", {}, void 0),
            _jsx("div", Object.assign({ className: "row" }, { children: _jsx("div", Object.assign({ className: "col" }, { children: _jsx("div", Object.assign({ style: { display: 'flex', justifyContent: 'space-between' } }, { children: _jsx("p", Object.assign({ className: "font-weight-bold" }, { children: "Download a human-readable version of the SDN List." }), void 0) }), void 0) }), void 0) }), void 0),
            _jsx("div", Object.assign({ className: "row" }, { children: _jsx("div", Object.assign({ style: { borderTop: 'solid', borderTopWidth: '1px', width: '100%' } }, { children: _jsxs(Table, Object.assign({ responsive: true, className: "border border-primary" }, { children: [_jsx("thead", Object.assign({ className: "ofac__bg-lightgray" }, { children: _jsxs("tr", { children: [_jsx("th", Object.assign({ scope: "col", style: { width: '13%' } }, { children: "File Name" }), void 0),
                                        _jsx("th", Object.assign({ scope: "col", style: { width: '70%' } }, { children: "Description" }), void 0),
                                        _jsx("th", { scope: "col", style: { width: '7%' } }, void 0),
                                        _jsx("th", { scope: "col", "aria-label": "Downloads Column", className: "text-center", style: { width: '10%' } }, void 0)] }, void 0) }), void 0),
                            _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", Object.assign({ className: "ofac_table-cell font-weight-bold" }, { children: _jsx("span", Object.assign({ style: { marginRight: '68px' } }, { children: "SDNLIST.PDF" }), void 0) }), void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell font-weight-normal" }, { children: "Complete Specially Designated Nationals List (in PDF format)" }), void 0),
                                            _jsx("td", { className: "ofac_table-cell font-weight-normal" }, void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell text-center" }, { children: _jsx("a", Object.assign({ className: "usa-button usa-button", href: "https://www.treasury.gov/ofac/downloads/sdnlist.pdf", target: "_blank", "aria-label": "human-readble-sdn-list", rel: "noreferrer" }, { children: "Download" }), void 0) }), void 0)] }, void 0),
                                    _jsxs("tr", { children: [_jsx("td", Object.assign({ className: "ofac_table-cell font-weight-bold" }, { children: "SDNNEW24.PDF" }), void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell font-weight-normal" }, { children: "Changes to the Specially Designated Nationals List (in PDF format)" }), void 0),
                                            _jsx("td", { className: "ofac_table-cell font-weight-normal" }, void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell text-center" }, { children: _jsx("a", Object.assign({ className: "usa-button usa-button", href: "https://www.treasury.gov/ofac/downloads/sdnnew24.pdf", target: "_blank", "aria-label": "human-readble-sdn-list", rel: "noreferrer" }, { children: "Download" }), void 0) }), void 0)] }, void 0)] }, void 0)] }), void 0) }), void 0) }), void 0),
            _jsx("hr", {}, void 0),
            _jsx("div", Object.assign({ className: "row" }, { children: _jsx("div", Object.assign({ className: "col" }, { children: _jsx("div", Object.assign({ style: { display: 'flex', justifyContent: 'space-between' } }, { children: _jsx("p", Object.assign({ className: "font-weight-bold" }, { children: "Download data specifications, schemas, and technical information" }), void 0) }), void 0) }), void 0) }), void 0),
            _jsx("div", Object.assign({ className: "row" }, { children: _jsx("div", Object.assign({ style: { borderTop: 'solid', borderTopWidth: '1px', width: '100%' } }, { children: _jsxs(Table, Object.assign({ responsive: true, className: "border border-primary" }, { children: [_jsx("thead", Object.assign({ className: "ofac__bg-lightgray" }, { children: _jsxs("tr", { children: [_jsx("th", Object.assign({ scope: "col", style: { width: '13%' } }, { children: "File Name" }), void 0),
                                        _jsx("th", Object.assign({ scope: "col", style: { width: '70%' } }, { children: "Description" }), void 0),
                                        _jsx("th", { scope: "col", style: { width: '7%' } }, void 0),
                                        _jsx("th", { scope: "col", "aria-label": "Downloads Column", className: "text-center", style: { width: '10%' } }, void 0)] }, void 0) }), void 0),
                            _jsx("tbody", { children: getXSDRows() }, void 0)] }), void 0) }), void 0) }), void 0)] }), void 0));
};
export default SdnList;
