import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { React } from './../import';
const { useEffect, useState } = React;
import axios from 'axios';
import SubHeader from './Components/SubHeader';
import { Table } from 'react-bootstrap';
import TableCell from './Components/TableCell';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactPaginate from 'react-paginate';
import ErrorDetails from './Components/ErrorDetails';
import Utils from '../utils';
const { REACT_APP_HOST_URL, REACT_APP_API_GET_DELTA_FILE_ARCHIVE, REACT_APP_API_DOWNLOAD_DELTA, REACT_APP_ARCHIVE_OF_CHANGES_URL, } = process.env;
const SystemStatus = () => {
    const [state, setState] = useState({
        data: [{
                fileName: '',
                size: 0,
                sizeFormatted: '',
                downloadLink: '',
                publishDisplayDate: undefined
            }],
        year: undefined,
        perPage: 10,
        page: 0,
        currentPage: 0,
        pages: 0,
        error: new Error(''),
    });
    useEffect(() => {
        Utils.setMeta('name', 'Office of Foreign Assets Control\'s Archive of Published Delta Files');
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getData(state.year);
    }, []);
    const getData = (year) => {
        if (year === undefined) {
            year = (new Date()).getFullYear();
        }
        axios({
            method: "post",
            url: `${REACT_APP_HOST_URL}${REACT_APP_API_GET_DELTA_FILE_ARCHIVE}`,
            params: {
                year: year
            },
        })
            .then((response) => {
            const { perPage } = state;
            const list = response.data;
            setState(Object.assign(Object.assign({}, state), { data: response.data, currentPage: 0, page: 0, pages: Math.ceil(list.length / perPage), year: year }));
        })
            .catch((response) => {
            setState(Object.assign(Object.assign({}, state), { error: response }));
        });
    };
    const getYears = () => {
        const startYear = 2022;
        const currentYear = (new Date()).getFullYear();
        const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
        const yearArray = range(currentYear, startYear, -1);
        const lastItem = yearArray[yearArray.length - 1];
        return (_jsx("ul", Object.assign({ style: { listStyleType: 'none' } }, { children: yearArray.map((year) => (_jsx("li", Object.assign({ style: { float: 'left', paddingLeft: '4px' }, onClick: e => handleYearClick(e, year) }, { children: _jsx("a", Object.assign({ href: "", className: "link-508" }, { children: year === lastItem ? year.toString() : year.toString() + ' |' }), void 0) }), year))) }), void 0));
    };
    const handleYearClick = (event, year) => {
        event.preventDefault();
        setState(Object.assign(Object.assign({}, state), { year }));
        getData(year);
    };
    const handlePageClick = (event) => {
        const page = event.selected;
        state.currentPage = page;
        setState(Object.assign(Object.assign({}, state), { page }));
    };
    const downloadDelta = (filename) => {
        axios({
            method: "post",
            url: `${REACT_APP_HOST_URL}${REACT_APP_API_DOWNLOAD_DELTA}`,
            params: {
                filename: filename
            },
        })
            .then((response) => {
            const link = document.createElement('a');
            link.href = response.data;
            link.setAttribute('download', filename);
            link.setAttribute('target', "_blank");
            document.body.appendChild(link);
            link.click();
        })
            .catch((response) => {
            setState(Object.assign(Object.assign({}, state), { error: response }));
        });
    };
    const rows = [];
    const { page, perPage, pages, data } = state;
    const items = data.slice(page * perPage, (page + 1) * perPage);
    let index = 0;
    for (const row of items) {
        if (row.publishDisplayDate !== undefined) {
            rows.push(_jsxs("tr", { children: [_jsx(TableCell, { value: row.fileName }, void 0),
                    _jsx(TableCell, { value: new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(new Date(row.publishDisplayDate)) }, void 0),
                    _jsx(TableCell, { value: row.sizeFormatted }, void 0),
                    _jsx("td", Object.assign({ className: "ofac_table-cell text-center" }, { children: _jsx("button", Object.assign({ className: "usa-button usa-button", "aria-label": row.fileName, onClick: () => downloadDelta(row.downloadLink) }, { children: "Download" }), void 0) }), void 0)] }, index));
        }
        index++;
    }
    if (state.error.message != '') {
        return (_jsx(ErrorDetails, { errorMessage: state.error.message }, void 0));
    }
    const descriptionText = `This Archive of Delta Files page provides the archive of published changes (Delta Files) in Extensible Markup ` +
        `Language (XML) file format. For the archive of published changes prior to September 22, 2022, please see the OFAC&apos;s human-readable ` +
        `<a href=${REACT_APP_ARCHIVE_OF_CHANGES_URL} className="blue-link" rel="noopener noreferrer" target="_blank">Archive of Changes</a>. ` +
        `Browse archive of published delta files by year.`;
    return (_jsxs("div", Object.assign({ className: "offset-1 col-md-10 offset-1" }, { children: [_jsx("title", { children: "Archive of Published Delta Files - Sanctions List Service" }, void 0),
            _jsx(SubHeader, { headerTitle: "Archive of Published Delta Files", headerDetails: "" }, void 0),
            _jsx(Row, Object.assign({ style: { paddingLeft: '15px' } }, { children: _jsx("p", { children: _jsx("div", { style: { whiteSpace: 'pre-wrap' }, dangerouslySetInnerHTML: { __html: descriptionText } }, void 0) }, void 0) }), void 0),
            _jsx(Row, { children: getYears() }, void 0),
            _jsx(Row, Object.assign({ style: { paddingLeft: '15px' } }, { children: _jsxs("p", { children: [_jsx("strong", { children: state.year }, void 0), " Archived Delta Files"] }, void 0) }), void 0),
            _jsx(Row, { children: _jsx(Col, { children: _jsxs("div", Object.assign({ className: " border border-primary" }, { children: [_jsxs(Table, Object.assign({ responsive: true, className: "border border-primary" }, { children: [_jsx("thead", Object.assign({ className: "table-subheader" }, { children: _jsxs("tr", { children: [_jsx("th", Object.assign({ scope: "col" }, { children: "File Name" }), void 0),
                                                _jsx("th", Object.assign({ scope: "col" }, { children: "Date Published" }), void 0),
                                                _jsx("th", Object.assign({ scope: "col" }, { children: "Size" }), void 0),
                                                _jsx("th", { scope: "col", "aria-label": "Downloads Column", className: "text-center" }, void 0)] }, void 0) }), void 0),
                                    _jsx("tbody", { children: rows }, void 0)] }), void 0),
                            _jsx("div", Object.assign({ className: "pagination-center" }, { children: _jsx(ReactPaginate, { previousLabel: 'previous', nextLabel: 'next', pageCount: pages, breakLabel: '...', breakClassName: 'break-me', pageRangeDisplayed: 5, marginPagesDisplayed: 2, onPageChange: handlePageClick, forcePage: state.currentPage, containerClassName: 'pagination', activeClassName: 'active-508' }, void 0) }), void 0)] }), void 0) }, void 0) }, void 0)] }), void 0));
};
export default SystemStatus;
