import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, React } from './../../import';
import { Link, useHistory } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import { Header as USWDSHeader } from '@trussworks/react-uswds';
import logo from '../../logo.png';
const { REACT_APP_HOST_URL, REACT_APP_API_GET_FILE_EXPORTS, REACT_APP_API_API_DOCUMENT_FILENAME } = process.env;
const link = `${REACT_APP_HOST_URL}${REACT_APP_API_GET_FILE_EXPORTS}${'/' + REACT_APP_API_API_DOCUMENT_FILENAME}`;
const Header = (props) => {
    const state = {
        pageName: props.pageName,
        pathName: props.pathName,
    };
    const historyObject = useHistory();
    const currentPath = historyObject.location.pathname;
    const currentLocation = '';
    const isNotHomepage = currentPath !== '/' && currentPath !== '/PublishPreview';
    return (_jsxs(USWDSHeader, { children: [_jsx("meta", { charSet: "utf-8" }, void 0),
            _jsx("meta", { httpEquiv: "X-UA-Compatible", content: "IE=edge" }, void 0),
            _jsx("meta", { name: "viewport", content: "width=device-width, initial-scale=1, shrink-to-fit=no" }, void 0),
            _jsx("meta", { name: "Description", content: "A application for use by OFAC personnel" }, void 0),
            _jsx(Navbar, Object.assign({ className: "ofac__fg-white navbar-header" }, { children: _jsxs(Navbar.Brand, Object.assign({ as: Link, to: `${currentLocation}/`, className: "navbar-brand ofac__branding" }, { children: [_jsx("img", { src: logo, width: "60", height: "60", className: "d-inline-block align-top", alt: "US Treasury Seal" }, void 0),
                        _jsx("span", Object.assign({ className: "ml-2 nav-resize" }, { children: "OFAC Sanctions List Service" }), void 0)] }), void 0) }), void 0),
            { isNotHomepage }
                &&
                    _jsx(Navbar, Object.assign({ style: { backgroundColor: '#E8E8E8' }, expand: "lg" }, { children: _jsxs(Container, { children: [_jsx(Navbar.Toggle, { "aria-controls": "basic-navbar-nav" }, void 0),
                                _jsx(Navbar.Collapse, Object.assign({ id: "basic-navbar-nav" }, { children: _jsxs(Nav, { children: [_jsx(Nav.Link, Object.assign({ as: Link, to: `${currentLocation}/SdnList`, className: "mx-5", style: { color: 'black', fontWeight: 'bold' } }, { children: "SDN List" }), void 0),
                                            _jsx(Nav.Link, Object.assign({ as: Link, to: `${currentLocation}/ConsolidatedList`, className: "mx-5", style: { color: 'black', fontWeight: 'bold' } }, { children: "Consolidated List" }), void 0),
                                            _jsx(Nav.Link, Object.assign({ as: Link, to: `${currentLocation}/CustomizeSanctionsDataset`, className: "mx-5", style: { color: 'black', fontWeight: 'bold' } }, { children: "Customize Dataset" }), void 0),
                                            _jsx(Nav.Link, Object.assign({ as: Link, to: `${currentLocation}/DeltaFileArchive`, className: "mx-5", style: { color: 'black', fontWeight: 'bold' } }, { children: "Archives" }), void 0),
                                            _jsx("a", Object.assign({ href: link, className: "mx-5 nav-link", style: { color: 'black', fontWeight: 'bold' }, rel: "noopener noreferrer", target: "_blank" }, { children: "API Documentation" }), void 0)] }, void 0) }), void 0)] }, void 0) }), void 0)] }, void 0));
};
export default Header;
