import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
const { REACT_APP_HOST_URL, } = process.env;
const ErrorDetails = (props) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // Error path
        return (_jsxs("div", { children: [_jsx("h4", { children: "An error has occurred." }, void 0),
                _jsx("br", {}, void 0), _jsx("p", { children: "There was a problem loading the site content..." }, void 0),
                _jsx("p", Object.assign({ style: { color: 'red' } }, { children: props.errorMessage }), void 0)] }, void 0));
    }
    else {
        const linktostatic = process.env.REACT_APP_STATIC_HOST_URL;
        return (_jsxs("div", { children: [_jsx("div", Object.assign({ style: { color: 'red', fontWeight: "bold" } }, { children: "Error Occurred!" }), void 0),
                _jsx("p", { children: "We are having difficulty with the SLS application at the moment. An error report has been sent to the support team. For the moment you may access critical list files at this URL" }, void 0),
                _jsx("p", { children: _jsx("a", Object.assign({ href: linktostatic }, { children: "SDN Lists | OFAC - Sanctions List Service (static version) (treas.gov)" }), void 0) }, void 0),
                _jsx("p", { children: "For technical support, please contact OFAC at 1-800-540-6322, Option 3." }, void 0)] }, void 0));
    }
};
export default ErrorDetails;
