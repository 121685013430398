import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
const { REACT_APP_HOST_URL, } = process.env;
// Note: DeltaFileUpload has its own ways to handle errors in FileUploadHandleError
// Other views are assumed to be using HandleErrorDisplay before this gets called.
// https://reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: Error,
            errorInfo: { componentStack: '' }
        };
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        console.warn(error, errorInfo);
        this.setState({
            error: error,
            errorInfo: errorInfo // Good to log the componentStack but it won't look right when displayed
        });
    }
    render() {
        if (this.state.hasError) {
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                // Error path
                return (_jsxs("div", { children: [_jsx("h2", { children: "An error has occurred." }, void 0),
                        _jsxs("details", Object.assign({ style: { whiteSpace: 'pre-wrap' } }, { children: [this.state.error.toString(), _jsx("br", {}, void 0), HandleErrorDisplay(this.state.error)] }), void 0)] }, void 0));
            }
            else {
                const linktostatic = process.env.REACT_APP_STATIC_HOST_URL;
                return (_jsxs("div", { children: [_jsx("div", Object.assign({ style: { color: 'red', fontWeight: "bold" } }, { children: "Error Occurred!" }), void 0),
                        _jsx("p", { children: "We are having difficulty with the SLS application at the moment. An error report has been sent to the support team. For the moment you may access critical list files at this URL" }, void 0),
                        _jsx("p", { children: _jsx("a", Object.assign({ href: linktostatic }, { children: "SDN Lists | OFAC - Sanctions List Service (static version) (treas.gov)" }), void 0) }, void 0),
                        _jsx("p", { children: "For technical support, please contact OFAC at 1-800-540-6322, Option 3." }, void 0)] }, void 0));
            }
        }
        // Normally, just render children
        return this.props.children;
    }
}
export default ErrorBoundary;
export function HandleErrorDisplay(response) {
    var _a, _b, _c, _d, _e, _f;
    // https://stackoverflow.com/questions/4725603/variable-undefined-vs-typeof-variable-undefined
    if (typeof ((_b = (_a = response.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.Message) === 'undefined') {
        return (_d = (_c = response.response) === null || _c === void 0 ? void 0 : _c.request) === null || _d === void 0 ? void 0 : _d.response;
    }
    else {
        return (_f = (_e = response.response) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.Message;
    }
}
