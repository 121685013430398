import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { RangeInput } from '@trussworks/react-uswds';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import WouldLikeHeading from './Components/WouldLikeHeading';
import ErrorDetails from './Components/ErrorDetails';
import Sort from './Abstractions/Sort';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';
import LoadingOverlay from './Components/LoadingOverlay';
// eslint-disable-next-line react/display-name
const { REACT_APP_HOST_URL, REACT_APP_API_SEARCH, REACT_APP_SANCTIONS_SEARCH_DETAILS_URL, REACT_APP_OFAC_HOME_URL, REACT_APP_API_SANTIONSEARCH_DATA, REACT_APP_API_GET_FILE_EXPORTS, REACT_APP_API_API_DOCUMENT_FILENAME } = process.env;
const PublicationPreview = () => {
    var _a;
    const [isLoading, setIsLoading] = useState(false);
    const [showFullSearchDescription, setFullSearchDescription] = useState(false);
    const [state, setState] = useState({
        countryList: [],
        sanctionsProgram: [],
        sdnTypeList: [],
        ListFieldList: [],
        selectedPrograms: [],
        selectedCountry: '',
        selectedSdnType: '',
        selectedList: '',
        name: '',
        address: '',
        city: '',
        idNumber: '',
        stateProvince: '',
        nameScore: 100,
        KeyState: false,
        showSearchResult: false,
        validationMessage: '',
        sortName: Sort.Asc,
        sortNameIcon: faSort,
        sortAddress: Sort.Asc,
        sortAddressIcon: faSort,
        sortType: Sort.Asc,
        sortTypeIcon: faSort,
        sortProgram: Sort.Asc,
        sortProgramIcon: faSort,
        sortLists: Sort.Asc,
        sortListsIcon: faSort,
        sortNameScore: Sort.Asc,
        sortNameScoreIcon: faSort,
        perPage: 10,
        page: 0,
        pages: 0,
        data: [{
                id: 0,
                name: '',
                address: '',
                type: '',
                programs: '',
                lists: '',
                nameScore: 0,
            }],
        error: new Error(''),
    });
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        const response = axios({
            method: "post",
            url: `${REACT_APP_HOST_URL}${REACT_APP_API_SANTIONSEARCH_DATA}`,
        }).then((response) => {
            setState(Object.assign(Object.assign({}, state), { countryList: response.data.result.countryList, sanctionsProgram: response.data.result.sanctionsProgram, sdnTypeList: response.data.result.typeList, ListFieldList: response.data.result.listFieldList }));
        }).catch((response) => {
            setState(Object.assign(Object.assign({}, state), { error: response }));
        });
    }, []);
    const resetFilter = () => {
        const toggle = !state.KeyState;
        setState(Object.assign(Object.assign({}, state), { KeyState: toggle, nameScore: 100, name: '', city: '', selectedCountry: '', selectedPrograms: [], selectedSdnType: '', address: '', selectedList: '', idNumber: '', stateProvince: '', showSearchResult: false, validationMessage: '', data: [], page: 0, pages: 0 }));
    };
    const hasSearchCriteria = () => {
        return state.name.trim() !== '' ||
            state.city.trim() !== '' ||
            state.idNumber.trim() !== '' ||
            state.stateProvince.trim() !== '' ||
            state.selectedPrograms.length !== 0 ||
            state.selectedSdnType !== '' ||
            state.selectedCountry !== '' ||
            state.selectedList !== '' ||
            state.address !== '';
    };
    const handleNameScore = (score) => {
        if (score > 100)
            score = 100;
        else if (score < 50)
            score = 50;
        setState(Object.assign(Object.assign({}, state), { nameScore: score }));
    };
    const search = (e) => {
        e.preventDefault();
        if (!hasSearchCriteria()) {
            setState(Object.assign(Object.assign({}, state), { validationMessage: 'At least one search parameter is required.' }));
            return;
        }
        else
            setState(Object.assign(Object.assign({}, state), { validationMessage: '' }));
        if (state.selectedPrograms.includes("All") && state.selectedPrograms.length > 1) {
            state.selectedPrograms = [];
            state.selectedPrograms.push("All");
        }
        setIsLoading(true);
        const payload = {
            name: state.name,
            city: state.city,
            idNumber: state.idNumber,
            stateProvince: state.stateProvince,
            nameScore: state.nameScore,
            country: state.selectedCountry,
            programs: state.selectedPrograms,
            type: state.selectedSdnType,
            address: state.address,
            list: state.selectedList
        };
        axios({
            url: `${REACT_APP_HOST_URL}${REACT_APP_API_SEARCH}`,
            method: 'POST',
            data: payload,
        })
            .then((response) => {
            if (response.data.success === false) {
                setState(Object.assign(Object.assign({}, state), { validationMessage: response.data.errorMessage, showSearchResult: false }));
            }
            else {
                const { perPage } = state;
                const list = response.data;
                setState(Object.assign(Object.assign({}, state), { data: response.data, pages: Math.ceil(list.length / perPage), showSearchResult: true, validationMessage: '' }));
            }
            setIsLoading(false);
        })
            .catch((response) => {
            setState(Object.assign(Object.assign({}, state), { error: response }));
            setIsLoading(false);
        });
    };
    const handlePageClick = (event) => {
        const page = event.selected;
        setState(Object.assign(Object.assign({}, state), { page }));
    };
    const onSort = (event, sortKey) => {
        const { data, sortName, sortAddress, sortType, sortProgram, sortLists, sortNameScore } = state;
        if (sortKey == 'name') {
            const sort = sortName;
            if (sort == Sort.Asc) {
                data.sort((a, b) => {
                    return (a[sortKey] || '').localeCompare(b[sortKey]);
                });
                setState(Object.assign(Object.assign({}, state), { data: data, sortName: Sort.Desc, sortNameIcon: faSortDown, sortAddressIcon: faSort, sortTypeIcon: faSort, sortProgramIcon: faSort, sortListsIcon: faSort, sortNameScoreIcon: faSort }));
            }
            else if (sort == Sort.Desc) {
                data.sort((b, a) => {
                    return (a[sortKey] || '').localeCompare(b[sortKey]);
                });
                setState(Object.assign(Object.assign({}, state), { data: data, sortName: Sort.Asc, sortNameIcon: faSortUp, sortAddressIcon: faSort, sortTypeIcon: faSort, sortProgramIcon: faSort, sortListsIcon: faSort, sortNameScoreIcon: faSort }));
            }
        }
        else if (sortKey == 'address') {
            const sort = sortAddress;
            if (sort == Sort.Asc) {
                data.sort((a, b) => {
                    return (a[sortKey] || '').localeCompare(b[sortKey]);
                });
                setState(Object.assign(Object.assign({}, state), { data: data, sortAddress: Sort.Desc, sortAddressIcon: faSortDown, sortNameIcon: faSort, sortTypeIcon: faSort, sortProgramIcon: faSort, sortListsIcon: faSort, sortNameScoreIcon: faSort }));
            }
            else if (sort == Sort.Desc) {
                data.sort((b, a) => {
                    return (a[sortKey] || '').localeCompare(b[sortKey]);
                });
                setState(Object.assign(Object.assign({}, state), { data: data, sortAddress: Sort.Asc, sortAddressIcon: faSortUp, sortNameIcon: faSort, sortTypeIcon: faSort, sortProgramIcon: faSort, sortListsIcon: faSort, sortNameScoreIcon: faSort }));
            }
        }
        else if (sortKey == 'type') {
            const sort = sortType;
            if (sort == Sort.Asc) {
                data.sort((a, b) => {
                    return (a[sortKey] || '').localeCompare(b[sortKey]);
                });
                setState(Object.assign(Object.assign({}, state), { data: data, sortType: Sort.Desc, sortTypeIcon: faSortDown, sortNameIcon: faSort, sortAddressIcon: faSort, sortProgramIcon: faSort, sortListsIcon: faSort, sortNameScoreIcon: faSort }));
            }
            else if (sort == Sort.Desc) {
                data.sort((b, a) => {
                    return (a[sortKey] || '').localeCompare(b[sortKey]);
                });
                setState(Object.assign(Object.assign({}, state), { data: data, sortType: Sort.Asc, sortTypeIcon: faSortUp, sortNameIcon: faSort, sortAddressIcon: faSort, sortProgramIcon: faSort, sortListsIcon: faSort, sortNameScoreIcon: faSort }));
            }
        }
        else if (sortKey == 'program') {
            const sort = sortProgram;
            if (sort == Sort.Asc) {
                data.sort((a, b) => {
                    return (a[sortKey] || '').localeCompare(b[sortKey]);
                });
                setState(Object.assign(Object.assign({}, state), { data: data, sortProgram: Sort.Desc, sortProgramIcon: faSortDown, sortNameIcon: faSort, sortAddressIcon: faSort, sortTypeIcon: faSort, sortListsIcon: faSort, sortNameScoreIcon: faSort }));
            }
            else if (sort == Sort.Desc) {
                data.sort((b, a) => {
                    return (a[sortKey] || '').localeCompare(b[sortKey]);
                });
                setState(Object.assign(Object.assign({}, state), { data: data, sortProgram: Sort.Asc, sortProgramIcon: faSortUp, sortNameIcon: faSort, sortAddressIcon: faSort, sortTypeIcon: faSort, sortListsIcon: faSort, sortNameScoreIcon: faSort }));
            }
        }
        else if (sortKey == 'list') {
            const sort = sortLists;
            if (sort == Sort.Asc) {
                data.sort((a, b) => {
                    return (a[sortKey] || '').localeCompare(b[sortKey]);
                });
                setState(Object.assign(Object.assign({}, state), { data: data, sortLists: Sort.Desc, sortListsIcon: faSortDown, sortNameIcon: faSort, sortAddressIcon: faSort, sortTypeIcon: faSort, sortProgramIcon: faSort, sortNameScoreIcon: faSort }));
            }
            else if (sort == Sort.Desc) {
                data.sort((b, a) => {
                    return (a[sortKey] || '').localeCompare(b[sortKey]);
                });
                setState(Object.assign(Object.assign({}, state), { data: data, sortLists: Sort.Asc, sortListsIcon: faSortUp, sortNameIcon: faSort, sortAddressIcon: faSort, sortTypeIcon: faSort, sortProgramIcon: faSort, sortNameScoreIcon: faSort }));
            }
        }
        else if (sortKey == 'score') {
            const sort = sortNameScore;
            if (sort == Sort.Asc) {
                data.sort((a, b) => {
                    return (a[sortKey] || '').localeCompare(b[sortKey]);
                });
                setState(Object.assign(Object.assign({}, state), { data: data, sortNameScore: Sort.Desc, sortNameScoreIcon: faSortDown, sortNameIcon: faSort, sortAddressIcon: faSort, sortTypeIcon: faSort, sortListsIcon: faSort, sortProgramsIcon: faSort }));
            }
            else if (sort == Sort.Desc) {
                data.sort((b, a) => {
                    return (a[sortKey] || '').localeCompare(b[sortKey]);
                });
                setState(Object.assign(Object.assign({}, state), { data: data, sortNameScore: Sort.Asc, sortNameScoreIcon: faSortUp, sortNameIcon: faSort, sortAddressIcon: faSort, sortTypeIcon: faSort, sortListsIcon: faSort, sortProgramsIcon: faSort }));
            }
        }
    };
    const rows = [];
    const { page, perPage, pages, data } = state;
    const items = data.slice(page * perPage, (page + 1) * perPage);
    for (const row of items) {
        rows.push(_jsxs("tr", Object.assign({ "data-item": row }, { children: [_jsx("td", Object.assign({ "data-title": "Name" }, { children: _jsx("a", Object.assign({ href: REACT_APP_SANCTIONS_SEARCH_DETAILS_URL + row.id, target: "_blank", rel: "noreferrer" }, { children: row.name }), void 0) }), void 0),
                _jsx("td", Object.assign({ "data-title": "Address" }, { children: row.address }), void 0),
                _jsx("td", Object.assign({ "data-title": "Type" }, { children: row.type }), void 0),
                _jsx("td", Object.assign({ "data-title": "Programs" }, { children: row.programs }), void 0),
                _jsx("td", Object.assign({ "data-title": "List" }, { children: row.lists }), void 0),
                _jsx("td", Object.assign({ "data-title": "Score" }, { children: row.nameScore }), void 0)] }), row.name + row.id));
    }
    if (state.error.message != '') {
        return (_jsx(ErrorDetails, { errorMessage: state.error.message }, void 0));
    }
    const showFullSearchDescriptionHandler = () => {
        setFullSearchDescription(!showFullSearchDescription);
    };
    const descriptionText = () => {
        const description = "This Sanctions List Search application (\"Sanctions List Search\") is designed to facilitate the use of the Specially Designated Nationals and Blocked Persons list (\"SDN List\") and other sanctions lists administered " +
            "by OFAC, including the Foreign Sanctions Evaders List, the Sectoral Sanctions Identifications List, the List of Foreign Financial Institutions Subject to Correspondent Account or Payable - Through Account Sanctions, " +
            "the Non-SDN Palestinian Legislative Council List, the Non - SDN Menu - Based Sanctions List, and the Non - SDN Communist Chinese Military Companies List. Given the number of lists that now reside in the Sanctions List " +
            "Search tool, it is strongly recommended that users pay close attention to the program codes associated with each returned record. These program codes indicate how a true hit on a returned value should be treated. " +
            "The Sanctions List Search tool uses approximate string matching to identify possible matches between word or character strings as entered into Sanctions List Search, and any name or name component as it appears on " +
            "the SDN List and / or the various other sanctions lists.To aid users of the tool, Sanctions List Search contains a feature entitled \"Minimum Name Score\" that functions on a sliding scale, allowing for a user to set " +
            "a threshold (i.e., a fuzziness rating) for the closeness of any potential match returned as a result of a user's search. This feature enables Sanctions List Search to detect certain misspellings or other " +
            "incorrectly entered text, and will return near, or proximate, matches, based on the confidence rating set by the user via the slider - bar.OFAC does not provide recommendations with regard to the appropriateness " +
            "of any specific confidence rating.Sanctions List Search is one tool offered to assist users in utilizing the SDN List and/or the various other sanctions lists; use of Sanctions List Search is not a substitute " +
            "for undertaking appropriate due diligence.The use of Sanctions List Search does not limit any criminal or civil liability for any act undertaken as a result of, or in reliance on, such use. ";
        return showFullSearchDescription ? description : description.slice(0, 584);
    };
    const link = `${REACT_APP_HOST_URL}${REACT_APP_API_GET_FILE_EXPORTS}${'/' + REACT_APP_API_API_DOCUMENT_FILENAME}`;
    return _jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: "col-12" }, { children: [_jsx("h1", { children: "HOME" }, void 0),
                _jsx(Row, Object.assign({ style: { paddingLeft: '15px' } }, { children: _jsxs("p", { children: [_jsx("a", Object.assign({ href: REACT_APP_OFAC_HOME_URL, className: "blue-link", rel: "noopener noreferrer", target: "_blank" }, { children: "OFAC" }), void 0), " provides users with easy access to regularly updated ready-to-download Sanctions lists. Users can choose to download from either the Specially Designated Nationals (SDN) List or the Consolidated (non-SDN) List. OFAC also provides users with the option to create custom sets of data based on selected Sanctions lists and/or programs through the Customize Sanctions Dataset tool."] }, void 0) }), void 0),
                _jsx("hr", {}, void 0),
                _jsx("div", { className: "ofac__voffset-2" }, void 0),
                _jsx(WouldLikeHeading, {}, void 0),
                _jsx("div", Object.assign({ className: "row mt-1 " }, { children: _jsxs("ul", Object.assign({ className: "usa-card-group ofac__voffset-2" }, { children: [_jsx("li", Object.assign({ className: "desktop:grid-col-12 usa-card" }, { children: _jsx("div", Object.assign({ className: "usa-card__container shadow-2" }, { children: _jsx("div", Object.assign({ className: "usa-card__body" }, { children: _jsxs("div", { children: [_jsx("h3", Object.assign({ style: { color: 'blue' } }, { children: "Search OFAC's Sanctions Lists" }), void 0),
                                                _jsx(Row, Object.assign({ style: { paddingLeft: '15px' } }, { children: _jsxs("p", { children: [descriptionText(), _jsxs("a", Object.assign({ href: "#", onClick: showFullSearchDescriptionHandler }, { children: [" Read ", showFullSearchDescription ? "Less" : "More"] }), void 0)] }, void 0) }), void 0),
                                                _jsx("div", { children: _jsx("form", Object.assign({ onSubmit: search }, { children: _jsxs("div", Object.assign({ className: "grid-container" }, { children: [_jsx(LoadingOverlay, { isLoading: isLoading }, void 0),
                                                                _jsxs("div", Object.assign({ className: "grid-row grid-gap" }, { children: [_jsxs("div", Object.assign({ className: "desktop:grid-col" }, { children: [_jsx("label", Object.assign({ className: "usa-label", htmlFor: "input-type-name" }, { children: "Type:" }), void 0),
                                                                                _jsx(Select, { name: "SDNType-selection", id: "SDNType-selection", isClearable: true, className: "ofac__select", "aria-hidden": "false", placeholder: "All", onChange: (e) => setState(Object.assign(Object.assign({}, state), { selectedSdnType: !e ? "" : e.value })), options: state.sdnTypeList.map((type, idx) => ({ value: type.description, label: type.description })) }, state.KeyState.toString())] }), void 0),
                                                                        _jsxs("div", Object.assign({ className: "desktop:grid-col" }, { children: [_jsx("label", Object.assign({ className: "usa-label", htmlFor: "input-type-name" }, { children: "Name:" }), void 0),
                                                                                _jsx("input", { className: "usa-input", id: "input-type-name", name: "input-type-name", onChange: (e) => setState(Object.assign(Object.assign({}, state), { name: e.target.value })) }, state.KeyState.toString())] }), void 0),
                                                                        _jsxs("div", Object.assign({ className: "desktop:grid-col" }, { children: [_jsx("label", Object.assign({ className: "usa-label", htmlFor: "input-type-id" }, { children: "ID #/Digital Currency Address:" }), void 0),
                                                                                _jsx("input", { className: "usa-input", id: "input-type-id", name: "input-type-id", onChange: (e) => setState(Object.assign(Object.assign({}, state), { idNumber: e.target.value })) }, state.KeyState.toString())] }), void 0),
                                                                        _jsxs("div", Object.assign({ className: "desktop:grid-col" }, { children: [_jsx("label", Object.assign({ className: "usa-label", htmlFor: "input-type-name" }, { children: "Program:" }), void 0),
                                                                                _jsx(Select, { name: "program-selection", id: "program-selection", isMulti: true, isSearchable: true, className: "ofac__select", "aria-hidden": "false", "aria-label": "filter by user name", tabIndex: 0, placeholder: "All", onChange: (e) => setState(Object.assign(Object.assign({}, state), { selectedPrograms: e.map((row) => { return row.value; }) })), options: state.sanctionsProgram.map((program, idx) => ({ value: program.description, label: program.description })) }, state.KeyState.toString())] }), void 0)] }), void 0),
                                                                _jsxs("div", Object.assign({ className: "grid-row grid-gap" }, { children: [_jsxs("div", Object.assign({ className: "desktop:grid-col" }, { children: [_jsx("label", Object.assign({ className: "usa-label", htmlFor: "input-type-address" }, { children: "Address:" }), void 0),
                                                                                _jsx("input", { className: "usa-input", id: "input-type-address", name: "input-type-address", onChange: (e) => setState(Object.assign(Object.assign({}, state), { address: e.target.value })) }, state.KeyState.toString())] }), void 0),
                                                                        _jsxs("div", Object.assign({ className: "desktop:grid-col" }, { children: [_jsx("label", Object.assign({ className: "usa-label", htmlFor: "input-type-city" }, { children: "City:" }), void 0),
                                                                                _jsx("input", { className: "usa-input", id: "input-type-city", name: "input-type-city", onChange: (e) => setState(Object.assign(Object.assign({}, state), { city: e.target.value })) }, state.KeyState.toString())] }), void 0),
                                                                        _jsxs("div", Object.assign({ className: "desktop:grid-col" }, { children: [_jsx("label", Object.assign({ className: "usa-label", htmlFor: "input-type-state" }, { children: "State/Province:" }), void 0),
                                                                                _jsx("input", { className: "usa-input", id: "input-type-state", name: "input-type-state", onChange: (e) => setState(Object.assign(Object.assign({}, state), { stateProvince: e.target.value })) }, state.KeyState.toString())] }), void 0),
                                                                        _jsxs("div", Object.assign({ className: "desktop:grid-col" }, { children: [_jsx("label", Object.assign({ className: "usa-label", htmlFor: "input-type-name" }, { children: "Country:" }), void 0),
                                                                                _jsx(Select, { name: "country-selection", id: "country-selection", isClearable: true, className: "ofac__select", "aria-hidden": "false", placeholder: "All", onChange: (e) => setState(Object.assign(Object.assign({}, state), { selectedCountry: !e ? "" : e.value })), options: state.countryList.map((country, idx) => ({ value: country.description, label: country.description })) }, state.KeyState.toString())] }), void 0)] }), void 0),
                                                                _jsxs("div", Object.assign({ className: "grid-row grid-gap" }, { children: [_jsxs("div", Object.assign({ className: "desktop:grid-col-3" }, { children: [_jsx("label", Object.assign({ className: "usa-label", htmlFor: "input-type-name" }, { children: "List:" }), void 0),
                                                                                _jsx(Select, { name: "list-selection", id: "list-selection", isClearable: true, className: "ofac__select", "aria-hidden": "false", placeholder: "All", onChange: (e) => setState(Object.assign(Object.assign({}, state), { selectedList: !e ? "" : e.value })), options: state.ListFieldList.map((list, idx) => ({ value: list.description, label: list.description })) }, state.KeyState.toString())] }), void 0),
                                                                        _jsxs("div", Object.assign({ className: "desktop:grid-col-6" }, { children: [_jsx("label", Object.assign({ className: "usa-label", htmlFor: "usa-range" }, { children: "Minimum Name Score:" }), void 0),
                                                                                _jsxs("div", Object.assign({ style: { display: "flex" } }, { children: [_jsx(RangeInput, { id: "usa-range", name: "score", className: "usa-range", type: "range", min: "50", max: "100", step: "1", value: state.nameScore, onChange: (e) => setState(Object.assign(Object.assign({}, state), { nameScore: e.target.value })), role: "slider" }, void 0),
                                                                                        _jsx("input", { className: "usa-input", id: "input-type-range", name: "input-type-range", type: "number", min: "50", max: "100", value: state.nameScore, onChange: (e) => setState(Object.assign(Object.assign({}, state), { nameScore: e.target.value })), onBlur: (e) => handleNameScore(e.target.value), style: { width: "35%", marginLeft: "2%" } }, state.KeyState.toString())] }), void 0),
                                                                                _jsx("span", Object.assign({ style: {
                                                                                        fontWeight: 'bold',
                                                                                        color: 'red',
                                                                                    } }, { children: state.validationMessage }), void 0)] }), void 0),
                                                                        _jsxs("div", Object.assign({ className: "desktop:grid-col-3" }, { children: [_jsx("div", Object.assign({ className: "grid-row grid-gap-lg" }, { children: _jsx("label", Object.assign({ className: "usa-label", htmlFor: "input-type-range" }, { children: "\u00A0" }), void 0) }), void 0),
                                                                                _jsx("div", Object.assign({ className: "grid-row grid-gap-lg", style: { display: "flex", justifyContent: "flex-end", paddingTop: "2%" } }, { children: _jsxs("div", { children: [_jsx("button", Object.assign({ className: "usa-button", type: "submit", "aria-label": "Search" }, { children: "Search" }), void 0),
                                                                                            _jsx("button", Object.assign({ className: "usa-button usa-button--outline", type: "reset", onClick: () => resetFilter(), "aria-label": "Reset Filter" }, { children: "Reset" }), void 0)] }, void 0) }), void 0)] }), void 0)] }), void 0),
                                                                _jsx("div", Object.assign({ className: "grid-row grid-gap-lg" }, { children: _jsxs("div", Object.assign({ className: "desktop:grid-col" }, { children: [(state.showSearchResult && state.validationMessage.trim() == "" && (rows === null || rows === void 0 ? void 0 : rows.length) === 0) && (_jsx("div", Object.assign({ style: {
                                                                                    fontWeight: 'bold',
                                                                                    color: 'red',
                                                                                    textAlign: 'center'
                                                                                } }, { children: "Your search has not returned any results." }), void 0)),
                                                                            (state.showSearchResult && (rows === null || rows === void 0 ? void 0 : rows.length) > 0) && (_jsxs("div", { children: [_jsxs("h6", { children: ["Lookup Results: ", (_a = state.data) === null || _a === void 0 ? void 0 : _a.length, " Found"] }, void 0),
                                                                                    _jsx("div", Object.assign({ style: { borderTop: 'solid', borderTopWidth: '1px', width: '100%' } }, { children: _jsxs(Table, Object.assign({ responsive: true, className: "border border-primary", id: "searchTable" }, { children: [_jsx("thead", Object.assign({ className: "ofac__bg-lightgray" }, { children: _jsxs("tr", { children: [_jsxs("th", Object.assign({ scope: "col" }, { children: ["Name", ' ', _jsx(FontAwesomeIcon, { onClick: e => onSort(e, 'name'), icon: state.sortNameIcon }, void 0)] }), void 0),
                                                                                                            _jsxs("th", Object.assign({ scope: "col" }, { children: ["Address", ' ', _jsx(FontAwesomeIcon, { onClick: e => onSort(e, 'address'), icon: state.sortAddressIcon }, void 0)] }), void 0),
                                                                                                            _jsxs("th", Object.assign({ scope: "col" }, { children: ["Type", ' ', _jsx(FontAwesomeIcon, { onClick: e => onSort(e, 'type'), icon: state.sortTypeIcon }, void 0)] }), void 0),
                                                                                                            _jsxs("th", Object.assign({ scope: "col" }, { children: ["Program(s)", ' ', _jsx(FontAwesomeIcon, { onClick: e => onSort(e, 'program'), icon: state.sortProgramIcon }, void 0)] }), void 0),
                                                                                                            _jsxs("th", Object.assign({ scope: "col" }, { children: ["List", ' ', _jsx(FontAwesomeIcon, { onClick: e => onSort(e, 'list'), icon: state.sortListsIcon }, void 0)] }), void 0),
                                                                                                            _jsxs("th", Object.assign({ scope: "col" }, { children: ["Score", ' ', _jsx(FontAwesomeIcon, { onClick: e => onSort(e, 'score'), icon: state.sortNameScoreIcon }, void 0)] }), void 0)] }, void 0) }), void 0),
                                                                                                _jsx("tbody", { children: rows }, void 0)] }), void 0) }), void 0),
                                                                                    _jsx("div", Object.assign({ className: "pagination-center" }, { children: _jsx(ReactPaginate, { previousLabel: 'previous', nextLabel: 'next', pageCount: pages, breakLabel: '...', breakClassName: 'break-me', pageRangeDisplayed: 5, marginPagesDisplayed: 2, onPageChange: handlePageClick, containerClassName: 'pagination', activeClassName: 'active' }, void 0) }), void 0)] }, void 0))] }), void 0) }), void 0)] }), void 0) }), void 0) }, void 0)] }, void 0) }), void 0) }), void 0) }), void 0),
                            _jsx("li", Object.assign({ className: "tablet:grid-col-4 usa-card" }, { children: _jsxs("div", Object.assign({ className: "usa-card__container shadow-2" }, { children: [_jsx("header", Object.assign({ className: "usa-card__header" }, { children: _jsx("h2", Object.assign({ className: "usa-card__heading", style: { color: 'blue' } }, { children: "Access the Specially Designated Nationals (SDN) List" }), void 0) }), void 0),
                                        _jsx("div", Object.assign({ className: "usa-card__body" }, { children: _jsx("p", { children: "Published by OFAC, the SDN list contains a list of \u201CSpecially Designated Nationals\u201D who are individuals and companies owned or controlled by, or acting for or on behalf of, targeted countries. to download the data set for the SDN sanctions list. It also lists individuals, groups, and entities, such as terrorists and narcotics traffickers designated under programs that are not country-specific." }, void 0) }), void 0),
                                        _jsx("div", Object.assign({ className: "usa-card__footer display-flex flex-column flex-align-end" }, { children: _jsx(Link, Object.assign({ to: '/SdnList', className: "usa-button", "aria-label": "Open SDN List" }, { children: "Open" }), void 0) }), void 0)] }), void 0) }), void 0),
                            _jsx("li", Object.assign({ className: "tablet:grid-col-4 usa-card" }, { children: _jsxs("div", Object.assign({ className: "usa-card__container shadow-2" }, { children: [_jsx("header", Object.assign({ className: "usa-card__header" }, { children: _jsx("h2", Object.assign({ className: "usa-card__heading", style: { color: 'blue' } }, { children: "Access the Consolidated (non-SDN) List" }), void 0) }), void 0),
                                        _jsx("div", Object.assign({ className: "usa-card__body" }, { children: _jsx("p", { children: "To make it easier to comply with OFAC's sanctions regulations, all non-SDN sanctions lists are consolidated in a set of data files. These consolidated files comply with all OFAC's existing data standards. Though separate lists, records in the consolidated files may also appear on the SDN list." }, void 0) }), void 0),
                                        _jsx("div", Object.assign({ className: "usa-card__footer display-flex flex-column flex-align-end" }, { children: _jsx(Link, Object.assign({ to: '/ConsolidatedList', className: "usa-button", "aria-label": "Open Consolidated List" }, { children: "Open" }), void 0) }), void 0)] }), void 0) }), void 0),
                            _jsx("li", Object.assign({ className: "tablet:grid-col-4 usa-card" }, { children: _jsxs("div", Object.assign({ className: "usa-card__container shadow-2" }, { children: [_jsx("header", Object.assign({ className: "usa-card__header" }, { children: _jsx("h2", Object.assign({ className: "usa-card__heading", style: { color: 'blue' } }, { children: "Generate Customized Sanctions Dataset" }), void 0) }), void 0),
                                        _jsx("div", Object.assign({ className: "usa-card__body" }, { children: _jsx("p", { children: "Rather than receiving the full SDN and/or Consolidated lists, set parameters to specify what Sanctions lists and/or sanctions programs you would like access to." }, void 0) }), void 0),
                                        _jsx("div", Object.assign({ className: "usa-card__footer display-flex flex-column flex-align-end" }, { children: _jsx(Link, Object.assign({ to: '/CustomizeSanctionsDataset', className: "usa-button", "aria-label": "Open Custom Dataset Generation" }, { children: "Open" }), void 0) }), void 0)] }), void 0) }), void 0),
                            _jsx("li", Object.assign({ className: "tablet:grid-col-6 usa-card" }, { children: _jsxs("div", Object.assign({ className: "usa-card__container shadow-2" }, { children: [_jsx("header", Object.assign({ className: "usa-card__header" }, { children: _jsx("h2", Object.assign({ className: "usa-card__heading", style: { color: 'blue' } }, { children: "SLS API Documentation" }), void 0) }), void 0),
                                        _jsx("div", Object.assign({ className: "usa-card__body" }, { children: _jsx("p", { children: "An application program interface is a mechanism that allows users to run multiple information systems to communicate, often in an automated or scripted fashion. OFACs Sanctions List Service (SLS) supports API get commands that allow users to bypass the SLS user interface and retrieve sanctions list data directly from SLS backend databases. The SLS API allows users to automate their data pulls from the SLS application. Click the Download button to obtain a copy of the API documentation." }, void 0) }), void 0),
                                        _jsx("div", Object.assign({ className: "usa-card__footer display-flex flex-column flex-align-end" }, { children: _jsx("a", Object.assign({ href: link, className: "usa-button usa-button", rel: "noopener noreferrer", target: "_blank" }, { children: "Download" }), void 0) }), void 0)] }), void 0) }), void 0),
                            _jsx("li", Object.assign({ className: "tablet:grid-col-6 usa-card" }, { children: _jsxs("div", Object.assign({ className: "usa-card__container shadow-2" }, { children: [_jsx("header", Object.assign({ className: "usa-card__header" }, { children: _jsx("h2", Object.assign({ className: "usa-card__heading", style: { color: 'blue' } }, { children: "View Archive..." }), void 0) }), void 0),
                                        _jsx("div", Object.assign({ className: "usa-card__body" }, { children: _jsx("p", { children: "Browse archive of published Delta Files by year." }, void 0) }), void 0),
                                        _jsx("div", Object.assign({ className: "usa-card__footer display-flex flex-column flex-align-end" }, { children: _jsx(Link, Object.assign({ to: '/DeltaFileArchive', className: "usa-button", "aria-label": "Open Delta File Archive" }, { children: "Open" }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0) }, void 0);
};
export default PublicationPreview;
