import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
class TableCell extends React.Component {
    constructor(props, state) {
        super(props, state);
    }
    render() {
        return (_jsx("td", { children: _jsx("div", Object.assign({ className: "ofac_table-cell" }, { children: this.props.value }), void 0) }, void 0));
    }
}
export default TableCell;
