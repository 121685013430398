import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { React } from './import';
import { Route, Switch, useHistory } from 'react-router-dom';
// import { library } from '@fortawesome/fontawesome-svg-core';
// import individual FA icons here for use in components.
// import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { AuthProvider } from './Views/Abstractions/GlobalState/AuthContext';
import PublicationPreview from './Views/PublicationPreview';
import SdnList from './Views/SdnList';
import ConsolidatedList from './Views/ConsolidatedList';
import CustomizeSanctionsDataset from './Views/CustomizeSanctionsDataset';
import DeltaFileArchive from './Views/DeltaFileArchive';
import Links from './Views/Components/Links';
import MainLayout from './Views/MainLayout';
import { useEffect, useState } from 'react';
import ReactLoading from "react-loading";
const App = () => {
    // add imported FA icons to the library for use in components.
    // library.add(faUserCircle);
    const [loading, setLoading] = useState(true);
    const historyObject = useHistory();
    const pathName = historyObject.location.pathname;
    useEffect(() => {
        setTimeout(() => setLoading(false), 6000);
    }, []);
    useEffect(() => {
        if (window.gas) {
            window.gas('send', 'pageview', pathName, pathName === '/' ? '/Home' : pathName);
        }
    }, [pathName]);
    if (loading === false) {
        return (_jsx(AuthProvider, { children: _jsx(MainLayout, Object.assign({ pageName: 'PublicationPreview', pathName: pathName }, { children: _jsxs(Switch, { children: [_jsx(Route, { exact: true, path: "/", component: PublicationPreview }, void 0),
                        _jsx(Route, { exact: true, path: Links.PublicationPreview, component: PublicationPreview }, void 0),
                        _jsx(Route, { exact: true, path: Links.SdnList, component: SdnList }, void 0),
                        _jsx(Route, { exact: true, path: Links.ConsolidatedList, component: ConsolidatedList }, void 0),
                        _jsx(Route, { exact: true, path: Links.CustomizeSanctionsDataset, component: CustomizeSanctionsDataset }, void 0),
                        _jsx(Route, { exact: true, path: Links.DeltaFileArchive, component: DeltaFileArchive }, void 0),
                        _jsx(Route, { exact: true, path: "/:id", component: PublicationPreview }, void 0)] }, void 0) }), void 0) }, void 0));
    }
    else
        return (_jsx(_Fragment, { children: _jsxs("div", { children: [_jsx("h4", Object.assign({ className: "loading-text" }, { children: "Loading OFAC's Sanctions List Service Application" }), void 0),
                    _jsx(ReactLoading, { type: "spinningBubbles", color: "#0000FF", height: 200, width: 100, className: "loading-screen" }, void 0)] }, void 0) }, void 0));
};
export default App;
