import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
class WouldLikeHeading extends React.Component {
    render() {
        return (_jsx(Row, { children: _jsx(Col, { children: _jsx("div", Object.assign({ className: "ofac_fancy-border" }, { children: _jsx("h2", { children: "I Would Like To" }, void 0) }), void 0) }, void 0) }, void 0));
    }
}
export default WouldLikeHeading;
