import { ACTION_TYPES } from '../Constants';
const { UPDATE_FULL_USER_OBJECT, UPDATE_USER_INFO, } = ACTION_TYPES;
const initialState = {
    fullUserObject: null,
    userID: "",
    userName: "",
    email: "",
    firstName: "",
    lastName: "",
    middleInitial: "",
    roleID: "",
    isActive: null,
    isContractor: null,
    createdByID: "",
    dateCreated: "",
    changedByID: "",
    dateChanged: "",
    fullName: "",
    fullNameWithMiddleInitial: "",
    status: "",
};
const rootReducer = (state, { type, payload }) => {
    switch (type) {
        case UPDATE_FULL_USER_OBJECT:
            return Object.assign(Object.assign({}, state), { fullUserObject: payload });
        case UPDATE_USER_INFO:
            return Object.assign(Object.assign({}, state), payload);
        default:
            throw new Error(`Unhandled action type: ${type}`);
    }
};
export { initialState, rootReducer };
