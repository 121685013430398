import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { React, Row } from '../import';
const { useEffect, useState } = React;
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import SubHeader from './Components/SubHeader';
import ErrorDetails from './Components/ErrorDetails';
import Utils from '../utils';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
const { REACT_APP_HOST_URL, REACT_APP_API_CONSOLIDATED_LIST, REACT_APP_API_DOWNLOAD_EXPORT, REACT_APP_API_GET_FILE_EXPORTS, REACT_APP_CONSOLIDATED_LIST_URL, REACT_APP_OTHER_SANCTIONS_LIST_URL } = process.env;
const ConsolidatedList = () => {
    var _a;
    const consolidated_list_url = REACT_APP_CONSOLIDATED_LIST_URL;
    const [state, setState] = useState({
        data: [{
                fileName: '',
                description: '',
                size: 0,
                sizeFormatted: '',
                downloadLink: '',
                lastUpdated: undefined,
                hashCodes: ''
            }],
        error: new Error(''),
    });
    useEffect(() => {
        Utils.setMeta('name', 'Office of Foreign Assets Control\'s Consolidated Sanctions List(Non - SDN Lists)');
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        axios({
            method: "post",
            url: `${REACT_APP_HOST_URL}${REACT_APP_API_CONSOLIDATED_LIST}`,
        })
            .then((response) => {
            setState(Object.assign(Object.assign({}, state), { data: response.data }));
        })
            .catch((response) => {
            setState(Object.assign(Object.assign({}, state), { error: response }));
        });
    }, []);
    const downloadExport = (filename) => {
        axios({
            method: "post",
            url: `${REACT_APP_HOST_URL}${REACT_APP_API_DOWNLOAD_EXPORT}`,
            params: {
                filename: filename
            },
        })
            .then((response) => {
            const link = document.createElement('a');
            link.href = response.data;
            link.setAttribute('download', filename);
            link.setAttribute('target', "_blank");
            document.body.appendChild(link);
            link.click();
        })
            .catch((response) => {
            setState(Object.assign(Object.assign({}, state), { error: response }));
        });
    };
    const goBack = () => {
        // Getting errors when using () directly in render view so adding function to make the call instead.
        history.back();
    };
    const getRows = () => {
        let rows = [];
        // Publication Exports will have a file size greater than 0.
        rows = state.data.filter((data) => data.size > 0).map((row, index) => {
            const { fileName, description, sizeFormatted, downloadLink, hashCodes } = row;
            const borderBottomStyle = !hashCodes ? {} : { borderBottom: 'none' };
            const parsedHashCodes = !hashCodes ? '' : JSON.parse(hashCodes);
            const link = `${REACT_APP_HOST_URL}${REACT_APP_API_GET_FILE_EXPORTS}${'/' + fileName}`;
            return (_jsxs(_Fragment, { children: [_jsxs("tr", { children: [_jsx("td", Object.assign({ className: "ofac_table-cell text-bold", style: borderBottomStyle }, { children: fileName }), void 0),
                            _jsx("td", Object.assign({ className: "ofac_table-cell text-normal", style: borderBottomStyle }, { children: description }), void 0),
                            _jsx("td", Object.assign({ className: "ofac_table-cell text-normal", style: borderBottomStyle }, { children: sizeFormatted }), void 0),
                            _jsx("td", Object.assign({ className: "ofac_table-cell text-center" }, { children: _jsx("a", Object.assign({ href: link, className: "usa-button usa-button", rel: "noopener noreferrer", target: "_blank" }, { children: "Download" }), void 0) }), void 0)] }, fileName),
                    hashCodes &&
                        _jsx("tr", { children: _jsx("td", Object.assign({ colSpan: 4, style: { textAlign: 'right', borderTop: 'none' }, className: "ofac_table-cell text-normal" }, { children: _jsx(Accordion, Object.assign({ allowZeroExpanded: true }, { children: _jsxs(AccordionItem, { children: [_jsx(AccordionItemHeading, { children: _jsx(AccordionItemButton, { children: "File Signatures" }, void 0) }, void 0),
                                            _jsx(AccordionItemPanel, { children: _jsx("div", Object.assign({ style: { textAlign: 'left' } }, { children: Object.keys(parsedHashCodes).map(k => _jsxs("p", Object.assign({ style: { wordBreak: 'break-all', whiteSpace: 'normal' } }, { children: [_jsx("strong", { children: k }, void 0), ": ", parsedHashCodes[k]] }), k)) }), void 0) }, void 0)] }, fileName) }), void 0) }), void 0) }, void 0)] }, void 0));
        });
        return rows;
    };
    const getXSDRows = () => {
        let rows = [];
        // XSD files will have a size of 0
        rows = state.data.filter((data) => data.size == 0).map((row, index) => {
            const { fileName, description, sizeFormatted, downloadLink } = row;
            const link = `${REACT_APP_HOST_URL}${REACT_APP_API_GET_FILE_EXPORTS}${'/' + fileName}`;
            return _jsxs("tr", { children: [_jsx("td", Object.assign({ className: "ofac_table-cell font-weight-bold" }, { children: fileName }), void 0),
                    _jsx("td", Object.assign({ className: "ofac_table-cell font-weight-normal" }, { children: description }), void 0),
                    _jsx("td", { className: "ofac_table-cell font-weight-normal" }, void 0),
                    _jsx("td", Object.assign({ className: "ofac_table-cell text-center" }, { children: _jsx("a", Object.assign({ href: link, className: "usa-button usa-button", rel: "noopener noreferrer", target: "_blank" }, { children: "Download" }), void 0) }), void 0)] }, index);
        });
        return rows;
    };
    if (state.error.message != '') {
        return (_jsx(ErrorDetails, { errorMessage: state.error.message }, void 0));
    }
    return (_jsxs("div", Object.assign({ className: "offset-1 col-md-10 offset-1" }, { children: [_jsx("title", { children: "Consolidated List - Sanctions List Service" }, void 0),
            _jsx(SubHeader, { headerTitle: "Consolidated List", headerDetails: "" }, void 0),
            _jsx(Row, Object.assign({ style: { paddingLeft: '15px' } }, { children: _jsxs("p", { children: ["In order to make it easier to comply with OFAC's sanctions regulations, the office is now offering all of its non-SDN sanctions lists in a consolidated set of data files \"the Consolidated Sanctions List\". These consolidated files comply with all OFAC's existing data standards.  In the future, if OFAC creates a new non-SDN style list, the office will add the new data associated with that list to these consolidated data files if appropriate. While the consolidated sanctions list data files are not part of OFAC's list of \u00A0", _jsx("a", Object.assign({ href: consolidated_list_url, className: "blue-link", rel: "noopener noreferrer", target: "_blank" }, { children: "Specially Designated Nationals and Blocked Persons \u00A0\"the SDN List,\" " }), void 0), "the records in these consolidated files may also appear on the SDN List."] }, void 0) }), void 0),
            _jsx("hr", {}, void 0),
            _jsx("div", Object.assign({ className: "row mt-1" }, { children: _jsx("div", Object.assign({ className: "col ofac__subheading text-capitalize text-primary" }, { children: _jsx("h3", { children: "Data Center - Consolidated List" }, void 0) }), void 0) }), void 0),
            _jsx("div", Object.assign({ className: "row" }, { children: _jsx("div", Object.assign({ className: "col" }, { children: _jsxs("div", Object.assign({ style: { display: 'flex', justifyContent: 'space-between' } }, { children: [_jsx("p", Object.assign({ className: "font-weight-bold" }, { children: "Choose from the various data file types below to download a complete version of the Consolidated List." }), void 0),
                            _jsx("p", { children: _jsxs("i", { children: ["Last Updated: ", ((_a = state.data[0]) === null || _a === void 0 ? void 0 : _a.lastUpdated) === undefined ? "" : new Date(state.data[0].lastUpdated).toLocaleDateString()] }, void 0) }, void 0)] }), void 0) }), void 0) }), void 0),
            _jsx("div", Object.assign({ className: "row" }, { children: _jsx("div", Object.assign({ style: {
                        borderTop: 'solid', borderTopWidth: '1px', width: '100%'
                    } }, { children: _jsxs(Table, Object.assign({ responsive: true, className: "border border-primary" }, { children: [_jsx("thead", Object.assign({ className: "ofac__bg-lightgray" }, { children: _jsxs("tr", { children: [_jsx("th", Object.assign({ scope: "col", style: { width: '13%' } }, { children: "File Name" }), void 0),
                                        _jsx("th", Object.assign({ scope: "col", style: { width: '70%' } }, { children: "Description" }), void 0),
                                        _jsx("th", Object.assign({ scope: "col", style: { width: '7%' } }, { children: "Size" }), void 0),
                                        _jsx("th", { scope: "col", className: "text-center", "aria-label": "Download Link Column", style: { width: '10%' } }, void 0)] }, void 0) }), void 0),
                            _jsx("tbody", { children: getRows() }, void 0)] }), void 0) }), void 0) }), void 0),
            _jsx("hr", {}, void 0),
            _jsx("div", Object.assign({ className: "row" }, { children: _jsx("div", Object.assign({ className: "col" }, { children: _jsx("div", Object.assign({ style: { display: 'flex', justifyContent: 'space-between' } }, { children: _jsxs("p", Object.assign({ className: "font-weight-bold" }, { children: ["Download a human-readable version of other sanctions lists.", _jsx("a", Object.assign({ href: REACT_APP_OTHER_SANCTIONS_LIST_URL, className: "blue-link", rel: "noopener noreferrer", target: "_blank" }, { children: " For background information on these lists, please visit the Additional Sanctions Lists page." }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0),
            _jsx("div", Object.assign({ className: "row" }, { children: _jsx("div", Object.assign({ style: { borderTop: 'solid', borderTopWidth: '1px', width: '100%' } }, { children: _jsxs(Table, Object.assign({ responsive: true, className: "border border-primary" }, { children: [_jsx("thead", Object.assign({ className: "ofac__bg-lightgray" }, { children: _jsxs("tr", { children: [_jsx("th", Object.assign({ scope: "col", style: { width: '13%' } }, { children: "File Name" }), void 0),
                                        _jsx("th", Object.assign({ scope: "col", style: { width: '70%' } }, { children: "Description" }), void 0),
                                        _jsx("th", { scope: "col", style: { width: '7%' } }, void 0),
                                        _jsx("th", { scope: "col", "aria-label": "Downloads Column", className: "text-center", style: { width: '10%' } }, void 0)] }, void 0) }), void 0),
                            _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", Object.assign({ className: "ofac_table-cell font-weight-bold" }, { children: _jsx("span", Object.assign({ style: { marginRight: "84px" } }, { children: "SSILIST.PDF" }), void 0) }), void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell font-weight-normal" }, { children: "Complete Sectoral Sanctions Identifications List (SSI List in PDF format)" }), void 0),
                                            _jsx("td", { className: "ofac_table-cell font-weight-normal" }, void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell text-center" }, { children: _jsx("a", Object.assign({ className: "usa-button usa-button", href: "https://www.treasury.gov/ofac/downloads/ssi/ssilist.pdf", target: "_blank", "aria-label": "human-readble-sdn-list", rel: "noreferrer" }, { children: "Download" }), void 0) }), void 0)] }, void 0),
                                    _jsxs("tr", { children: [_jsx("td", Object.assign({ className: "ofac_table-cell font-weight-bold" }, { children: "SSINEW24.PDF" }), void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell font-weight-normal" }, { children: "Changes to the Sectoral Sanctions Identifications List the last time it was published (Changes to the SSI List in PDF format)" }), void 0),
                                            _jsx("td", { className: "ofac_table-cell font-weight-normal" }, void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell text-center" }, { children: _jsx("a", Object.assign({ className: "usa-button usa-button", href: "\thttps://www.treasury.gov/ofac/downloads/ssi/ssinew24.pdf ", target: "_blank", "aria-label": "human-readble-sdn-list", rel: "noreferrer" }, { children: "Download" }), void 0) }), void 0)] }, void 0),
                                    _jsxs("tr", { children: [_jsx("td", Object.assign({ className: "ofac_table-cell font-weight-bold" }, { children: "FSELIST.PDF" }), void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell font-weight-normal" }, { children: "Complete Foreign Sanctions Evaders List (FSE List in PDF format)" }), void 0),
                                            _jsx("td", { className: "ofac_table-cell font-weight-normal" }, void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell text-center" }, { children: _jsx("a", Object.assign({ className: "usa-button usa-button", href: "https://www.treasury.gov/ofac/downloads/fse/fselist.pdf", target: "_blank", "aria-label": "human-readble-sdn-list", rel: "noreferrer" }, { children: "Download" }), void 0) }), void 0)] }, void 0),
                                    _jsxs("tr", { children: [_jsx("td", Object.assign({ className: "ofac_table-cell font-weight-bold" }, { children: "FSENEW22.PDF" }), void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell font-weight-normal" }, { children: "\tChanges to the Foreign Sanctions Evaders List the last time it was published  (Changes to the FSE list in PDF format)" }), void 0),
                                            _jsx("td", { className: "ofac_table-cell font-weight-normal" }, void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell text-center" }, { children: _jsx("a", Object.assign({ className: "usa-button usa-button", href: "https://www.treasury.gov/ofac/downloads/fse/fsenew22.pdf", target: "_blank", "aria-label": "human-readble-sdn-list", rel: "noreferrer" }, { children: "Download" }), void 0) }), void 0)] }, void 0),
                                    _jsxs("tr", { children: [_jsx("td", Object.assign({ className: "ofac_table-cell font-weight-bold" }, { children: "PLC_LIST.PDF" }), void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell font-weight-normal" }, { children: "Complete Palestinian Legislative Council List (NS-PLC List in PDF format)" }), void 0),
                                            _jsx("td", { className: "ofac_table-cell font-weight-normal" }, void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell text-center" }, { children: _jsx("a", Object.assign({ className: "usa-button usa-button", href: "https://ofac.treasury.gov/media/10411/download?inline", target: "_blank", "aria-label": "human-readble-sdn-list", rel: "noreferrer" }, { children: "Download" }), void 0) }), void 0)] }, void 0),
                                    _jsxs("tr", { children: [_jsx("td", Object.assign({ className: "ofac_table-cell font-weight-bold" }, { children: "PLCNEW23.PDF" }), void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell font-weight-normal" }, { children: "Changes to the Palestinian Legislative Council List the last time it was published (Changes to the NS-PLC List in PDF format)" }), void 0),
                                            _jsx("td", { className: "ofac_table-cell font-weight-normal" }, void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell text-center" }, { children: _jsx("a", Object.assign({ className: "usa-button usa-button", href: "https://ofac.treasury.gov/media/932301/download?inline", target: "_blank", "aria-label": "human-readble-sdn-list", rel: "noreferrer" }, { children: "Download" }), void 0) }), void 0)] }, void 0),
                                    _jsxs("tr", { children: [_jsx("td", Object.assign({ className: "ofac_table-cell font-weight-bold" }, { children: "CAPTALIST.PDF" }), void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell font-weight-normal" }, { children: "Complete Correspondent Account or Payable-Through Account Sanctions List (CAPTA List in PDF format)" }), void 0),
                                            _jsx("td", { className: "ofac_table-cell font-weight-normal" }, void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell text-center" }, { children: _jsx("a", Object.assign({ className: "usa-button usa-button", href: "https://www.treasury.gov/ofac/downloads/capta/captalist.pdf", target: "_blank", "aria-label": "human-readble-sdn-list", rel: "noreferrer" }, { children: "Download" }), void 0) }), void 0)] }, void 0),
                                    _jsxs("tr", { children: [_jsx("td", Object.assign({ className: "ofac_table-cell font-weight-bold" }, { children: "CAPTANEW22.PDF" }), void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell font-weight-normal" }, { children: "Changes to the Correspondent Account or Payable-Through Account Sanctions List the last time it was published (Changes to the CAPTA List in PDF format)" }), void 0),
                                            _jsx("td", { className: "ofac_table-cell font-weight-normal" }, void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell text-center" }, { children: _jsx("a", Object.assign({ className: "usa-button usa-button", href: "https://www.treasury.gov/ofac/downloads/capta/captanew22.pdf", target: "_blank", "aria-label": "human-readble-sdn-list", rel: "noreferrer" }, { children: "Download" }), void 0) }), void 0)] }, void 0),
                                    _jsxs("tr", { children: [_jsx("td", Object.assign({ className: "ofac_table-cell font-weight-bold" }, { children: "MBSLIST.PDF" }), void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell font-weight-normal" }, { children: "Complete Non-SDN Menu-Based Sanctions List (NS-MBS List in PDF format)" }), void 0),
                                            _jsx("td", { className: "ofac_table-cell font-weight-normal" }, void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell text-center" }, { children: _jsx("a", Object.assign({ className: "usa-button usa-button", href: "https://www.treasury.gov/ofac/downloads/mbs/mbslist.pdf", target: "_blank", "aria-label": "human-readble-sdn-list", rel: "noreferrer" }, { children: "Download" }), void 0) }), void 0)] }, void 0),
                                    _jsxs("tr", { children: [_jsx("td", Object.assign({ className: "ofac_table-cell font-weight-bold" }, { children: "MBSNEW24.PDF" }), void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell font-weight-normal" }, { children: "Changes to the Non-SDN Menu-Based Sanctions List the last time it was published (Changes to the NS-MBS List in PDF format)" }), void 0),
                                            _jsx("td", { className: "ofac_table-cell font-weight-normal" }, void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell text-center" }, { children: _jsx("a", Object.assign({ className: "usa-button usa-button", href: "https://www.treasury.gov/ofac/downloads/mbs/mbsnew24.pdf", target: "_blank", "aria-label": "human-readble-sdn-list", rel: "noreferrer" }, { children: "Download" }), void 0) }), void 0)] }, void 0),
                                    _jsxs("tr", { children: [_jsx("td", Object.assign({ className: "ofac_table-cell font-weight-bold" }, { children: "NSCMICLIST.PDF" }), void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell font-weight-normal" }, { children: "Complete Non-SDN Chinese Military-Industrial Complex Companies List (NS-CMIC List in PDF format)" }), void 0),
                                            _jsx("td", { className: "ofac_table-cell font-weight-normal" }, void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell text-center" }, { children: _jsx("a", Object.assign({ className: "usa-button usa-button", href: "https://www.treasury.gov/ofac/downloads/ccmc/nscmiclist.pdf", target: "_blank", "aria-label": "human-readble-sdn-list", rel: "noreferrer" }, { children: "Download" }), void 0) }), void 0)] }, void 0),
                                    _jsxs("tr", { children: [_jsx("td", Object.assign({ className: "ofac_table-cell font-weight-bold" }, { children: "NSCMICNEW21.PDF" }), void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell font-weight-normal" }, { children: "Changes to the Non-SDN Chinese Military-Industrial Complex Companies List the last time it was published  (Changes to the NS-CMIC List in PDF format)" }), void 0),
                                            _jsx("td", { className: "ofac_table-cell font-weight-normal" }, void 0),
                                            _jsx("td", Object.assign({ className: "ofac_table-cell text-center" }, { children: _jsx("a", Object.assign({ className: "usa-button usa-button", href: "https://www.treasury.gov/ofac/downloads/ccmc/nscmicnew21.pdf", target: "_blank", "aria-label": "human-readble-sdn-list", rel: "noreferrer" }, { children: "Download" }), void 0) }), void 0)] }, void 0)] }, void 0)] }), void 0) }), void 0) }), void 0),
            _jsx("hr", {}, void 0),
            _jsx("div", Object.assign({ className: "row" }, { children: _jsx("div", Object.assign({ className: "col" }, { children: _jsx("div", Object.assign({ style: { display: 'flex', justifyContent: 'space-between' } }, { children: _jsx("p", Object.assign({ className: "font-weight-bold" }, { children: "Download data specifications, schemas, and technical information" }), void 0) }), void 0) }), void 0) }), void 0),
            _jsx("div", Object.assign({ className: "row" }, { children: _jsx("div", Object.assign({ style: { borderTop: 'solid', borderTopWidth: '1px', width: '100%' } }, { children: _jsxs(Table, Object.assign({ responsive: true, className: "border border-primary" }, { children: [_jsx("thead", Object.assign({ className: "ofac__bg-lightgray" }, { children: _jsxs("tr", { children: [_jsx("th", Object.assign({ scope: "col", style: { width: '13%' } }, { children: "File Name" }), void 0),
                                        _jsx("th", Object.assign({ scope: "col", style: { width: '70%' } }, { children: "Description" }), void 0),
                                        _jsx("th", { scope: "col", style: { width: '7%' } }, void 0),
                                        _jsx("th", { scope: "col", "aria-label": "Downloads Column", className: "text-center", style: { width: '10%' } }, void 0)] }, void 0) }), void 0),
                            _jsx("tbody", { children: getXSDRows() }, void 0)] }), void 0) }), void 0) }), void 0)] }), void 0));
};
export default ConsolidatedList;
