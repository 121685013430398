import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useContext, useReducer } from 'react';
import { rootReducer, initialState } from './reducers';
import useActions from './actions';
export const AuthContext = createContext({});
export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(rootReducer, initialState);
    const actions = useActions(state, dispatch);
    /**
         useEffect(() => {
    
        }, []);
     */
    return (_jsx(AuthContext.Provider, Object.assign({ value: { state, actions } }, { children: children }), void 0));
};
export const useUserStore = () => useContext(AuthContext);
