import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { React } from './../../import';
import { ButtonGroup, GridContainer, Grid } from '@trussworks/react-uswds';
const SubHeader = (props) => {
    return (_jsx(GridContainer, Object.assign({ className: "subheader-container" }, { children: _jsxs(Grid, Object.assign({ row: true, className: "" }, { children: [_jsxs(Grid, Object.assign({ className: "subheader-child" }, { children: [_jsx(Grid, Object.assign({ className: "ofac__bg-blue ofac__fg-white ofac__subheading subheader-title" }, { children: props.headerTitle }), void 0),
                        _jsx(Grid, Object.assign({ className: "row ofac__voffset-1" }, { children: _jsx("p", Object.assign({ className: "col" }, { children: props.headerDetails }), void 0) }), void 0)] }), void 0),
                _jsx(Grid, Object.assign({ className: "subheader-child subheader-btn-section" }, { children: props.optionalButtonArray
                        &&
                            (_jsx(ButtonGroup, { children: props.optionalButtonArray }, void 0)) }), void 0)] }), void 0) }), void 0));
};
export default SubHeader;
