import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { React } from './../import';
import { Grid } from '@trussworks/react-uswds';
import Header from './Components/Header';
import Footer from './Components/Footer';
const MainLayout = (props) => {
    return (_jsxs(Grid, { children: [_jsx(Header, { pageName: props.pageName, pathName: props.pathName }, void 0),
            _jsx("main", Object.assign({ id: "content", className: "row main-layout ofac__voffset-2 mt-5" }, { children: props.children }), void 0),
            _jsx(Footer, {}, void 0)] }, void 0));
};
export default MainLayout;
