import { jsx as _jsx } from "react/jsx-runtime";
import 'react-app-polyfill/ie9';
import '@trussworks/react-uswds/lib/index.css';
import { React, ReactDOM } from './import';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './custom.scss';
import { BrowserRouter } from 'react-router-dom';
// Add this in your component file https://reactjs.org/warnings/invalid-hook-call-warning.html
require('react-dom');
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.React2 = require('react');
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const isDev = () => {
    return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
};
ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(BrowserRouter, Object.assign({ basename: '/Home/' }, { children: _jsx(App, {}, void 0) }), void 0) }, void 0), document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export { React };
