export default class Utils {
}
// Sets the content of a meta tag by name.
Utils.setMeta = (metaName, metaContent) => {
    const metas = document.getElementsByTagName('meta');
    if (metas === null || metas.length === 0) {
        return;
    }
    for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute(metaName) === 'description') {
            return metas[i].setAttribute('content', metaContent);
        }
    }
};
