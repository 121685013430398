import { ACTION_TYPES } from '../Constants';
const { UPDATE_FULL_USER_OBJECT, UPDATE_USER_INFO } = ACTION_TYPES;
const useActions = (state, dispatch) => {
    const actionGenerator = (actionType) => {
        return (data) => {
            dispatch({ type: actionType, payload: data });
        };
    };
    return {
        updateFullUserObject: actionGenerator(UPDATE_FULL_USER_OBJECT),
        updateUserInfo: actionGenerator(UPDATE_USER_INFO),
    };
};
export default useActions;
